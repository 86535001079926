import { FC, useEffect, useState } from "react";
import { Col, Form, Input, Select } from "antd";

import { ExpandableFilter } from "../../../components/ExpandableFilter";
import { IBank } from "../../../types";
import { getAllBank } from "../../../services/repositories";

const { Option } = Select;

interface FilterTransactionAccountListProps {
  onSubmit?: any;
}

interface FilterTransactionAccount {
  name?: string;
  accountType?: string;
  bankName?: string;
}

const accountType = [
  "Conta Corrente",
  "Poupança",
  "Conta Salário",
  "Caixa",
  "Carteira",
  "Outros",
];

const accountTypeOptions = accountType.map((type) => ({
  value: type,
  label: type,
}));

const FilterTransactionAccountList: FC<FilterTransactionAccountListProps> = ({
  onSubmit,
}) => {
  const [form] = Form.useForm<FilterTransactionAccount>();

  const [bank, setBank] = useState<IBank[]>([]);

  useEffect(() => {
    const getData = async () => {
      await getAllBank(1, 1000).then(({ data }) => {
        setBank(data.data);
      });
    };
    getData();
  }, []);

  const fields = [
    <Col span={6} key={1}>
      <Form.Item name={"name"} label={"Nome da conta"}>
        <Input allowClear placeholder="Digite o nome da conta" />
      </Form.Item>
    </Col>,
    <Col span={6} key={2}>
      <Form.Item name={"accountType"} label={"Tipo da conta"}>
        <Select
          allowClear
          showSearch
          placeholder="Selecione o tipo da conta"
          optionFilterProp="children"
          options={accountTypeOptions}
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={3}>
      <Form.Item name={"bankName"} label={"Banco"}>
        <Select
          optionFilterProp="children"
          showSearch
          allowClear
          placeholder="Selecione um banco"
        >
          {bank.map((bank) => {
            return (
              <Option key={bank.id} value={bank.name}>
                {bank.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>,
  ];

  const onFinish = (values: FilterTransactionAccount) => {
    if (onSubmit) {
      onSubmit({ ...values });
    }
  };

  const onClear = () => {
    onSubmit();
  };

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterTransactionAccountList };

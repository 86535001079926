import { RouteObject } from "react-router-dom";
import { CostCenter } from "./list";

export const CostCenterRouter: RouteObject = {
  path: "/cost-center",
  handle: { crumb: () => "Cadastros / Financeiro / Centro de custo" },
  children: [
    {
      index: true,
      element: <CostCenter />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};

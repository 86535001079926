import dayjs from "dayjs";
import { FC, useEffect } from "react";
import { Form, Modal, Switch, notification } from "antd";
import { Typography } from "antd";

import { Progress } from "antd";
import { ColumnsType } from "antd/es/table";

import {
  Financial,
  Installment,
  ModalProps,
} from "../../../types";
import { formatters } from "../../../utils";
import { Table } from "antd/lib";
import { api } from "../../../services/api";

const { Text } = Typography;

const InstallmentModal: FC<ModalProps<Financial>> = ({
  onClose,
  isModalOpen,
  data: financial,
  onRefreshData,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (financial) {
      const installmentsPaidStatus = financial.item.installment.reduce(
        (acc, installment, index) => {
          acc[`isPaid_${index}`] = installment.is_paid;
          return acc;
        },
        {} as Record<string, boolean>
      );
      console.log(installmentsPaidStatus);
      form.setFieldsValue(installmentsPaidStatus);
    }
  }, [financial, form]);

  const columns: ColumnsType<Financial> = [
    {
      title: "Contato",
      dataIndex: ["item", "client"],
      key: "client",
      ellipsis: true,
      render: (value, record) => {
        const { supplier } = record.item;

        return <div>{value?.name || supplier?.name || "-"}</div>;
      },
    },
    {
      title: "Cobrança",
      dataIndex: ["item", "payment_type"],
      key: "payment_type",
      ellipsis: true,
      width: 210,
      render: (paymentType: string, record) => {
        const paymentTypesMap: { [key: string]: string } = {
          SINGLE: "Único",
          RECURRING: "Recorrente",
          INSTALLMENT: "Parcelado",
        };
        return (
          <div>
            <div>{paymentTypesMap[paymentType] || paymentType}</div>
            {paymentType === "INSTALLMENT" && (
              <div style={{ marginTop: 5 }}>
                <div style={{ width: "150px" }}>
                  <Progress
                    percent={
                      (record.paidInstallments / record.totalInstallments) * 100
                    }
                    size="small"
                    strokeWidth={10}
                    status="active"
                    showInfo={false}
                  />
                  {`${record.paidInstallments}/${record.totalInstallments}x`}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Valor",
      dataIndex: ["item", "value"],
      key: "value",
      width: 120,
      ellipsis: true,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>{formatters.currency(value)}</div>
        );
      },
    },
  ];

  const installmentColumns: ColumnsType<Installment> = [
    {
      dataIndex: "due",
      key: "due",
      render: (due: string) => <div>{dayjs(due).format("DD/MM/YYYY")}</div>,
    },
    {
      key: "installmentNumber",
      render: (_, record, index) => {
        const totalInstallments = financial?.item?.installment?.length || 0;
        const currentInstallment = (index + 1).toString().padStart(2, "0");
        const totalInstallmentsFormatted = totalInstallments
          .toString()
          .padStart(2, "0");
        return (
          <div>{`${currentInstallment}/${totalInstallmentsFormatted}`}</div>
        );
      },
    },
    {
      dataIndex: "value",
      key: "value",
      render: (value: string) => (
        <div style={{ color: "#3030DA" }}>{formatters.currency(value)}</div>
      ),
    },
    {
      dataIndex: "is_paid",
      key: "is_paid",
      render: (value: boolean, record: Installment, index: number) => (
        <Form form={form}>
          <Form.Item
            name={`isPaid_${index}`}
            valuePropName="checked"
            style={{ padding: 0, margin: 0 }}
          >
            <Switch
              onChange={() => handleInstallmentAsPaid(record.id)}
              disabled={value}
            />
          </Form.Item>
        </Form>
      ),
    },
  ];

  const handleInstallmentAsPaid = async (installmentId: string | undefined) => {
    try {
      await api.put(
        `/financials/${financial?.item?.id}/installment/${installmentId}`
      );

      notification.success({
        message: "Sucesso",
        description: "Parcela paga com sucesso!",
      });
      if (onRefreshData) {
        onRefreshData();
      }
    } catch (error) {
      notification.error({
        message: "Erro",
        description:
          "Ocorreu um erro ao marcar a parcela como paga. Tente novamente.",
      });
    }
  };

  return (
    <Modal
      title="Parcelas"
      open={isModalOpen}
      onCancel={() => onClose()}
      onOk={() => onClose()}
      okText="Salvar"
      cancelButtonProps={{ type: "default", danger: true }}
      width={540}
    >
      <Table
        columns={columns}
        dataSource={financial ? [financial] : []}
        pagination={false}
        size="middle"
      />

      <div style={{ margin: "16px 10px" }}>
        <Text strong style={{ marginRight: "8px" }}>
          Descrição:
        </Text>
        <Text>{financial?.item?.description}</Text>
      </div>

      <Table
        columns={installmentColumns}
        dataSource={financial?.item?.installment || []}
        pagination={false}
        size="large"
        showHeader={false}
      />
    </Modal>
  );
};

export { InstallmentModal };

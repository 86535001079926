import { FC, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  message as Message,
  Modal,
  Select,
  Switch,
} from "antd";

import { api } from "../../../services/api";

import { IBankAccount, IPayment, ModalProps } from "../../../types";
import { getAllBankAccount } from "../../../services/repositories";

const paymentMethodsObject = [
  { label: "DINHEIRO", value: "DINHEIRO" },
  { label: "CARTÃO DE CRÉDITO", value: "CARTÃO DE CRÉDITO" },
  { label: "CARTÃO DE DÉBITO", value: "CARTÃO DE DÉBITO" },
  { label: "CHEQUE", value: "CHEQUE" },
  { label: "CREDIÁRIO", value: "CREDIÁRIO" },
  { label: "BOLETO BANCÁRIO", value: "BOLETO BANCÁRIO" },
  { label: "DEPÓSITO EM CONTA", value: "DEPÓSITO EM CONTA" },
  { label: "TRANSFERÊNCIA BANCÁRIA", value: "TRANSFERÊNCIA BANCÁRIA" },
  { label: "PIX", value: "PIX" },
  { label: "OUTROS", value: "OUTROS" },
];

const PaymentMethodModal: FC<ModalProps<IPayment>> = ({
  onClose,
  data,
  isModalOpen,
  onRefreshData,
}) => {
  const [message] = Message.useMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>([]);

  useEffect(() => {
    const getBankAccounts = async () => {
      try {
        const { data } = await getAllBankAccount({
          page: 1,
          perPage: 1000,
        });
        setBankAccounts(data);
      } catch (error) {
        console.error(error);
      }
    };

    getBankAccounts();
  }, []);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (data) {
        form.setFieldsValue({
          ...data,
          bankAccountId: data.bank_account_id,
          paymentType: data.payment_type,
          automaticLiquidation: data.automatic_liquidation,
        });
      }
    };
    if (isModalOpen) {
      getData().finally(() => setLoading(false));
    }
  }, [form, data, isModalOpen]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `Pagamento ${
            data.config.method === "put" ? "atualizado" : "cadastrado"
          } com sucesso!`
        );
        if (onRefreshData) {
          onRefreshData();
        }
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: any) => {
    if (data) {
      return await api.put(`/financials/payment-methods/${data.id}`, values);
    }
    return await api.post(`/financials/payment-methods/`, values);
  };

  return (
    <Modal
      title={`${data ? "Editar" : "Cadastrar"} pagamento`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          disabled={loading}
          danger
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="supplier_form_modal">
        <Form.Item
          name="name"
          label="Nome"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="bankAccountId"
          label="Conta de operação"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
          >
            {bankAccounts.map((bankAccount) => {
              return (
                <Select.Option key={bankAccount.id} value={bankAccount.id}>
                  {bankAccount.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="paymentType"
          label="Tipo de pagamento"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
          >
            {paymentMethodsObject.map((paymentMethod) => {
              return (
                <Select.Option
                  key={paymentMethod.value}
                  value={paymentMethod.value}
                >
                  {paymentMethod.label}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="automaticLiquidation"
          label="Liquidação automática"
          initialValue={false}
        >
          <Switch checkedChildren="SIM" unCheckedChildren="NÃO" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { PaymentMethodModal };

import { useState } from "react";
import { Tabs, TabsProps } from "antd";
import { Button } from "antd";
import {
  BankOutlined,
  BarChartOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { PageHeader } from "../../../components/PageHeader";
import { Content } from "../../../components/Content";
import { useModal } from "../../../hooks";
import { ChartOfAccountModal } from "../components/ChartOfAccountModal";

import { Receipt } from "./Receipt";
import { Payment } from "./Payment";

const ChartOfAccounts = () => {
  const [load, setLoad] = useState(false);

  const handleRefreshData = () => {
    setLoad(!load);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Recebimento",
      children: <Receipt load={load} handleRefreshData={handleRefreshData} />,
      icon: <BarChartOutlined />,
    },
    {
      key: "2",
      label: "Pagamento",
      children: <Payment load={load} handleRefreshData={handleRefreshData} />,
      icon: <BankOutlined />,
    },
  ];

  const { isOpen, handleCloseModal, handleOpenModal } =
    useModal(handleRefreshData);

  return (
    <>
      <PageHeader
        title="Plano de contas"
        backButton={false}
        actions={[
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => handleOpenModal()}
          >
            Cadastrar plano de contas
          </Button>,
        ]}
        crumb
      />

      <ChartOfAccountModal
        isModalOpen={isOpen}
        onClose={handleCloseModal}
        onRefreshData={handleRefreshData}
      />

      <Content width="calc(100% - 48px)" margin>
        <Tabs defaultActiveKey="1" items={items} />
      </Content>
    </>
  );
};

export { ChartOfAccounts };

import { useState, useEffect, FC, useCallback } from "react";

import { Button } from "antd";
import { ColumnsType } from "antd/es/table";

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { api } from "../../../services/api";

import { Content } from "../../../components/Content";
import { ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
import { FilterItemList } from "../components/FilterItemList";
import { IMeta, IPagination, IPmoc } from "../../../types";
import { PmocModal } from "../components/PmocModal";
import { useNavigate } from "react-router-dom";
import { formatters } from "../../../utils";
import { ActionTable } from "../../../components/ActionTable";

export const getPmocs = async (page = 1, perPage = 10, q?: any) => {
  return await api.get<IPagination<IPmoc>>(`/pmocs`, {
    params: {
      page,
      perPage,
      ...q,
    },
  });
};

const deleteItem = async (itemId: string) => {
  return await api.delete(`/pmocs/${itemId}`);
};

const Pmocs: FC = () => {
  const navigate = useNavigate();

  const [pmocs, setPmocs] = useState<IPmoc[]>([]);
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [filter, setFilter] = useState();
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });
  const [pmoc, setPmoc] = useState<IPmoc | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      await getPmocs(page, pageSize, filter)
        .then(({ data }) => {
          setPmocs(data?.data);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [filter]
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, filter, meta.per_page, handleChangePage]);

  const actions: ActionFunction<IPmoc> = (record) => {
    return [
      {
        key: "1",
        label: "Detalhar",
        icon: <SearchOutlined />,
        onClick: () => navigate(`${record.id}/equipments`),
      },
      {
        key: "2",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleShowModal("update", record),
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => {
          onDeletePmoc(record);
        },
      },
    ];
  };

  const columns: ColumnsType<IPmoc> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.toString().padStart(4, "0")}
          </div>
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 300,
    },
    {
      title: "Cliente",
      dataIndex: "client",
      key: "client",
      render: ({ name }) => name,
    },
    {
      title: "Técnico Resp.",
      dataIndex: "technicalManagers",
      key: "technicalManagers",
      render: (technicalManagers) => technicalManagers?.name || "N/A",
    },
    {
      title: "Técnico do PMOC",
      dataIndex: "users",
      key: "profilePmoc",
      render: (users) =>
        users.find((user: any) => user.profile === "TECHNICIAN").name,
    },
    {
      title: "Início",
      dataIndex: "startDate",
      key: "startDate",
      render: (date) => formatters.simpleDate(date),
    },
    {
      title: "Fim",
      dataIndex: "endDate",
      key: "endDate",
      render: (date) => formatters.simpleDate(date),
    },
  ];

  const onDeletePmoc = async (record: IPmoc) => {
    setLoading(true);
    await deleteItem(record.id)
      .then(() => handleRefreshData())
      .finally(() => {
        setLoading(false);
      });
  };

  const handleShowModal = (mode?: string, record?: IPmoc) => {
    if (mode === "update" && record) {
      setPmoc(record);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = (refresh?: boolean) => {
    setIsModalOpen(false);
    setPmoc(undefined);

    if (refresh) {
      handleRefreshData();
    }
  };

  const handleFilterData = (values: any) => {
    setFilter(values);
  };

  const handleRefreshData = () => {
    setLoad(!load);
  };

  return (
    <>
      <PageHeader
        title="PMOC"
        backButton={false}
        actions={[
          <Button
            key={"create_pmoc"}
            onClick={() => handleShowModal()}
            icon={<PlusOutlined />}
            type="primary"
          >
            Adicionar PMOC
          </Button>,
        ]}
        crumb
      />
      <PmocModal
        onClose={handleCloseModal}
        isModalOpen={isModalOpen}
        data={pmoc}
      />
      <Content
        width="calc(100% - 48px)"
        margin
        style={{ padding: "24px 40px 0" }}
      >
        <FilterItemList onSubmit={handleFilterData} />
      </Content>
      <Content width="calc(100% - 48px)" margin>
        <ActionTable
          actions={actions}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 30, 50],
            current: meta.current_page,
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
          }}
          size={"small"}
          loading={loading}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={pmocs}
        />
      </Content>
    </>
  );
};

export { Pmocs };

import { Form, Input, InputNumber, Select } from "antd";
import { useEffect, useState } from "react";
import { getCEST, getNCM } from "../../../../../services/repositories";
import { ICEST, INCM } from "../../../../../types";

const { Option } = Select;

const origin = [
  { label: "Nacional", value: 0 },
  { label: "Estrangeira (importação direta)", value: 1 },
  { label: "Estrangeira (adquirida no mercado interno)", value: 2 },
  { label: "Nacional com mais de 40% de conteúdo estrangeiro", value: 3 },
  {
    label: "Nacional produzida através de processos produtivos básicos",
    value: 4,
  },
  { label: "Nacional com menos de 40% de conteúdo estrangeiro", value: 5 },
  {
    label: "Estrangeira (importação direta) sem produto nacional similar",
    value: 6,
  },
  {
    label:
      "Estrangeira (adquirida no mercado interno) sem produto nacional similar",
    value: 7,
  },
];

interface BenefitCode {
  code: string;
  label: string;
}

const benefitCodes: BenefitCode[] = [
  { code: "00", label: "Tributada integralmente" },
  {
    code: "10",
    label: "Tributada e com cobrança do ICMS por substituição tributária",
  },
  { code: "20", label: "Tributada com redução de base de cálculo" },
  {
    code: "30",
    label:
      "Isenta ou não tributada e com cobrança do ICMS por substituição tributária",
  },
  { code: "40", label: "Isenta" },
  { code: "41", label: "Não tributada" },
  { code: "50", label: "Suspensão" },
  {
    code: "51",
    label:
      "Diferimento (a exigência do preenchimento das informações do ICMS diferido fica a critério de cada UF)",
  },
  { code: "60", label: "Cobrado anteriormente por substituição tributária" },
  {
    code: "70",
    label:
      "Tributada com redução de base de cálculo e com cobrança do ICMS por substituição tributária",
  },
  { code: "90", label: "Outras (regime Normal)" },
  {
    code: "101",
    label: "Tributada pelo Simples Nacional com permissão de crédito",
  },
  {
    code: "102",
    label: "Tributada pelo Simples Nacional sem permissão de crédito",
  },
  {
    code: "103",
    label: "Isenção do ICMS no Simples Nacional para faixa de receita bruta",
  },
  {
    code: "201",
    label:
      "Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    code: "202",
    label:
      "Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    code: "203",
    label:
      "Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária",
  },
  { code: "300", label: "Imune" },
  { code: "400", label: "Não tributada pelo Simples Nacional" },
  {
    code: "500",
    label:
      "ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
  },
  { code: "900", label: "Outras (regime Simples Nacional)" },
];

const TaxDataForm = () => {
  const [cest, setCest] = useState<ICEST[]>([]);
  const [ncm, setNcm] = useState<INCM[]>([]);

  useEffect(() => {
    const getData = async () => {
      await getCEST(1, 1000).then(({ data }) => {
        setCest(data);
      });

      await getNCM(1, 1000).then(({ data }) => {
        setNcm(data);
      });
    };
    getData();
  }, []);

  return (
    <>
      <Form.Item label="Código benefício" name="benefitCode">
        <Select
          optionFilterProp="children"
          showSearch
          allowClear
          placeholder="Selecione"
        >
          {benefitCodes.map((benefitCodes) => {
            return (
              <Option key={benefitCodes.code} value={benefitCodes.code}>
                {benefitCodes.label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item label="NCM" name="ncm">
        <Select
          allowClear
          showSearch
          optionFilterProp="children"
          placeholder="Selecione"
        >
          {ncm.map((ncm) => {
            return (
              <Option key={ncm.ncm_code} value={ncm.ncm_code}>
                {ncm.description}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item label="CEST" name="cest">
        <Select
          optionFilterProp="children"
          showSearch
          allowClear
          placeholder="Selecione"
          options={cest.map((cest) => {
            return {
              label: cest?.description,
              value: cest?.cest_code,
            };
          })}
        />
      </Form.Item>

      <Form.Item label="Origem" name="origin">
        <Select
          optionFilterProp="children"
          showSearch
          allowClear
          placeholder="Selecione"
          options={origin.map((origin) => {
            return {
              label: `${origin.value} – ${origin.label}`,
              value: origin.value,
            };
          })}
        />
      </Form.Item>

      <Form.Item name="netWeight" label="Peso líquido">
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item name="grossWeight" label="Peso bruto">
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item label="Número FCI" name="fci">
        <Input />
      </Form.Item>

      <Form.Item name="approximateTax" label="Valor aprox. tributo (%)">
        <InputNumber min={0.1} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item name="pisValue" label="Valor fixo PIS:">
        <InputNumber min={0.1} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item name="pisTaxSituation" label="Valor fixo PIS ST">
        <InputNumber min={0.1} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item name="cofinsValue" label="Valor fixo COFINS">
        <InputNumber min={0.1} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item name="cofinsStValue" label="Valor fixo COFINS ST">
        <InputNumber min={0.1} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item name="product_code" label="Código do produto">
        <InputNumber min={0.1} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item name="cfop" label="CFOP">
        <InputNumber min={0.1} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item name="icmsTaxSituation" label="Taxa de ICMS">
        <InputNumber min={0.1} style={{ width: "100%" }} />
      </Form.Item>
    </>
  );
};

export { TaxDataForm };

import { RouteObject } from "react-router-dom";
import { TechnicalManager } from "./list";

export const TechnicalManagerRouter: RouteObject = {
  path: "/technical-manager",
  handle: { crumb: () => "Cadastros / PMOC / Responsável técnico" },
  children: [
    {
      index: true,
      element: <TechnicalManager />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};

import { RouteObject } from "react-router-dom";
import { TransactionAccount } from "./list";

export const TransactionAccountRouter: RouteObject = {
  path: "/transaction-account",
  handle: { crumb: () => "Cadastros / Financeiro / Conta de operação" },
  children: [
    {
      index: true,
      element: <TransactionAccount />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};

import { useState } from "react";
import { App, Button } from "antd";
import { ColumnsType } from "antd/es/table";

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { getAllBankAccount } from "../../../services/repositories";
import { api } from "../../../services/api";
import { IBankAccount } from "../../../types";
import { usePagination } from "../../../hooks";
import { formatters } from "../../../utils";

import { Content } from "../../../components/Content";
import { ActionFunction } from "../../../components/ActionColumn";
import { ActionTable } from "../../../components/ActionTable";
import { PageHeader } from "../../../components/PageHeader";
import { FilterTransactionAccountList } from "../components/FilterTransactionAccountList";
import { TransactionAccountModal } from "../components/TransactionAccountModal";
import { TransactionAccountDetails } from "../components/TransactionAccountDetails";

const TransactionAccount = () => {
  const { modal, message } = App.useApp();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [bankAccount, setBankAccount] = useState<IBankAccount>();

  const {
    data: bankAccounts,
    isLoading,
    pagination,
    setFilter,
    handleRefreshData,
  } = usePagination(getAllBankAccount);

  const handleShowDetails = (record?: IBankAccount) => {
    setBankAccount(record);
    setShowDetails(true);
  };

  const handleShowModal = (mode?: string, record?: IBankAccount) => {
    if (mode === "update" && record) {
      setBankAccount(record);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setBankAccount(undefined);
  };

  const onDeleteTransactionAccount = async (record: IBankAccount) => {
    return modal.confirm({
      title: "Deseja deletar a conta de operação?",
      content: <>A conta será excluída permanentemente!</>,
      okText: "Deletar",
      okButtonProps: {
        type: "primary",
        danger: true,
      },
      cancelText: "Cancelar",
      cancelButtonProps: {
        type: "default",
        danger: true,
      },
      onOk: async () => {
        await api
          .delete(`/financials/bank-accounts/${record.id}`)
          .catch(() => message.error("Ops! Algo deu errado!"))
          .then(handleRefreshData);
      },
    });
  };

  const actions: ActionFunction<IBankAccount> = (record) => {
    return [
      {
        key: "1",
        label: "Detalhar",
        icon: <SearchOutlined />,
        onClick: () => {
          handleShowDetails(record);
        },
      },
      {
        key: "2",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => {
          handleShowModal("update", record);
        },
      },
      {
        key: "3",
        label: "Deletar",
        icon: <DeleteOutlined />,
        danger: true,
        onClick: () => onDeleteTransactionAccount(record),
      },
    ];
  };

  const columns: ColumnsType<IBankAccount> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value.match(/\d/g).slice(0, 4).join("")}
          </div>
        );
      },
    },
    {
      title: "Nome da conta",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Tipo da conta",
      dataIndex: "account_type",
      key: "account_type",
    },
    {
      title: "Banco",
      dataIndex: "bank",
      key: "bank",
      render: (bank) => bank?.name,
    },
    {
      title: "N° da agência",
      dataIndex: "agency_number",
      key: "agency_number",
    },
    {
      title: "N° da conta",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Saldo inicial",
      dataIndex: "initial_balance",
      key: "initial_balance",
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>{formatters.currency(value)}</div>
        );
      },
    },
    {
      title: "Saldo atual",
      dataIndex: "current_balance",
      key: "current_balance",
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>{formatters.currency(value)}</div>
        );
      },
    },
  ];

  return (
    <>
      <TransactionAccountModal
        data={bankAccount}
        isModalOpen={isModalOpen}
        onClose={handleCloseModal}
        onRefreshData={handleRefreshData}
      />
      <TransactionAccountDetails
        data={bankAccount}
        isModalOpen={showDetails}
        onClose={() => setShowDetails((prevState) => !prevState)}
      />
      <PageHeader
        title="Conta de operação"
        backButton={false}
        actions={[
          <Button
            icon={<PlusOutlined />}
            key={"create_contract"}
            type="primary"
            onClick={() => handleShowModal()}
          >
            Adicionar operação
          </Button>,
        ]}
        crumb
      />
      <Content
        width="calc(100% - 48px)"
        margin
        style={{ padding: "24px 40px 0" }}
      >
        <FilterTransactionAccountList onSubmit={setFilter} />
      </Content>
      <Content width="calc(100% - 48px)" margin>
        <ActionTable
          pagination={pagination}
          actions={actions}
          loading={isLoading}
          columns={columns}
          dataSource={bankAccounts}
        />
      </Content>
    </>
  );
};

export { TransactionAccount };

import { FC, useEffect, useState } from "react";

import {
  Button,
  Form,
  message as Message,
  Modal,
  Spin,
  Tabs,
  TabsProps,
  UploadProps,
} from "antd";

import { TOKEN_API, api } from "../../../services/api";
import { IProduct, ModalProps } from "../../../types";
import { ProductForm } from "./form/ProductForm";
import { TaxDataForm } from "./form/TaxDataForm";

const ProductModal: FC<ModalProps<IProduct>> = ({
  onClose,
  onRefreshData,
  isModalOpen,
  data: product,
}) => {
  const [message, context] = Message.useMessage();

  const [form] = Form.useForm();
  const [image, setImage] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      if (product) {
        form.setFieldsValue({
          ...product,
        });
        setImage(
          product.image
            ? [
                {
                  uid: "-1",
                  status: "done",
                  name: product.name,
                  url: product.image,
                  thumbUrl: process.env.REACT_APP_ENDPOINT + product.image,
                },
              ]
            : []
        );
      }
    }
  }, [form, isModalOpen, product]);

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose();
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }

    handleSubmitForm({ ...values, image: image[0]?.url })
      .then((data) => {
        message.success(
          `Item ${
            data.config.method === "put" ? "atualizado" : "criado"
          } com sucesso!`
        );
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
        if (onRefreshData) {
          onRefreshData();
        }
      });
  };

  const handleSubmitForm = async (values: IProduct) => {
    if (product) {
      return await api.put<IProduct>(`/products/${product.id}`, values);
    }
    return await api.post<IProduct>(`/products`, values);
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    action: process.env.REACT_APP_API + `/upload`,
    data: {
      folder: "products",
    },
    headers: {
      authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
    },
    fileList: image,
    onChange(info) {
      let newFileList = [...info.fileList];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });

      setImage(newFileList);

      if (info.file.status === "error") {
        if (info.file.response?.errors) {
          info.file.response.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error(`${info.file.name} Erro ao enviar o arquivo.`);
        }
      }
    },
    accept: "image/*",
    maxCount: 1,
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Produto",
      children: <ProductForm props={props} />,
    },
    {
      key: "2",
      label: "Dados Fiscais- NF-e",
      children: <TaxDataForm />,
    },
  ];

  return (
    <Modal
      title={`${product ? "Editar" : "Cadastrar"} Produto`}
      open={isModalOpen}
      width={750}
      centered
      onCancel={handleCloseModal}
      footer={[
        <Button key="back" onClick={handleCloseModal} danger disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      {context}
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="product_form_modal">
          <Tabs defaultActiveKey="1" items={items} />
        </Form>
      </Spin>
    </Modal>
  );
};

export { ProductModal };

import { useCallback, useEffect, useState } from "react";
import { App } from "antd";
import { ColumnsType } from "antd/es/table";

import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { api } from "../../../services/api";
import {
  ChartOfAccountGrandsons,
  ChartOfAccountSon,
  IMeta,
  IPagination,
} from "../../../types";
import { useModal } from "../../../hooks";

import { Content } from "../../../components/Content";
import { ActionFunction } from "../../../components/ActionColumn";
import { ActionTable } from "../../../components/ActionTable";

import { UpdateChartOfAccountModal } from "../components/UpdateChartOfAccountModal";

export async function getChartOfAccountSons(page = 1, perPage = 1000) {
  return await api.get<IPagination<ChartOfAccountSon>>(
    `/chart-of-accounts/sons`,
    {
      params: {
        page,
        perPage,
        fatherId: "46ecdcdc-3425-4526-9461-8050cc5b1685",
      },
    }
  );
}

interface PaymentProps {
  load: boolean;
  handleRefreshData: () => void;
}

const Payment = ({ load, handleRefreshData }: PaymentProps) => {
  const { modal, message } = App.useApp();

  const [chartOfAccountSon, setChartOfAccountSon] =
    useState<ChartOfAccountSon[]>();
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      await getChartOfAccountSons(page, pageSize)
        .then(({ data }) => {
          setChartOfAccountSon(data?.data);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, meta.per_page, handleChangePage]);

  const { data, isOpen, handleCloseModal, handleOpenModal } =
    useModal<ChartOfAccountSon>(handleRefreshData);

  const onDeleteChartOfAccount = async (record: ChartOfAccountSon) => {
    return modal.confirm({
      title: "Deseja deletar esse plano de conta?",
      content: <>O plano será excluído permanentemente!</>,
      okText: "Deletar",
      okButtonProps: {
        type: "primary",
        danger: true,
      },
      cancelText: "Cancelar",
      cancelButtonProps: {
        type: "default",
        danger: true,
      },
      onOk: async () => {
        await api
          .delete(`/chart-of-accounts/${record.id}`)
          .catch(() => message.error("Ops! Algo deu errado!"))
          .then(handleRefreshData);
      },
    });
  };

  const actions: ActionFunction<ChartOfAccountSon> = (record) => {
    return [
      {
        key: "2",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "3",
        label: "Deletar",
        icon: <DeleteOutlined />,
        danger: true,
        onClick: () => onDeleteChartOfAccount(record),
      },
    ];
  };

  const columns: ColumnsType<ChartOfAccountSon> = [
    {
      title: "Índice",
      dataIndex: "index",
      key: "index",
      width: 120,
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Disponível no DRE",
      dataIndex: "dre",
      key: "dre",
      render: (dre) =>
        dre === true ? (
          <CheckOutlined style={{ fontSize: "20px", color: "#4ECB73" }} />
        ) : (
          <CloseOutlined style={{ fontSize: "20px", color: "#F5222D" }} />
        ),
    },
  ];

  const grandsonColumns: ColumnsType<ChartOfAccountGrandsons> = [
    {
      title: "Índice",
      dataIndex: "index",
      key: "index",
      width: 120,
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Disponível no DRE",
      dataIndex: "dre",
      key: "dre",
      render: (dre) =>
        dre === true ? (
          <CheckOutlined style={{ fontSize: "20px", color: "#4ECB73" }} />
        ) : (
          <CloseOutlined style={{ fontSize: "20px", color: "#F5222D" }} />
        ),
    },
  ];

  return (
    <>
      <UpdateChartOfAccountModal
        isModalOpen={isOpen}
        onClose={handleCloseModal}
        onRefreshData={handleRefreshData}
        data={data}
      />

      <Content width="calc(100% - 48px)" margin>
        <ActionTable
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 30, 50],
            current: meta.current_page,
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
          }}
          actions={actions}
          loading={loading}
          columns={columns}
          dataSource={chartOfAccountSon}
          expandable={{
            expandedRowRender: (record) => (
              <ActionTable
                columns={grandsonColumns}
                dataSource={record.chartOfAccountGrandsons}
                rowKey="id"
                pagination={false}
                showHeader={false}
                actions={actions}
              />
            ),
          }}
        />
      </Content>
    </>
  );
};

export { Payment };

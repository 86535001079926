import { useCallback, useEffect, useState } from "react";
import { App, Progress, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { api } from "../../../services/api";
import { Financial, IMeta, IPagination } from "../../../types";
import { useModal } from "../../../hooks";
import { formatters } from "../../../utils";

import { Content } from "../../../components/Content";
import { ActionFunction } from "../../../components/ActionColumn";
import { ActionTable } from "../../../components/ActionTable";
import { FilterFinancialList } from "../components/FilterFinancialList";
import { UpdateFinancialModal } from "../components/UpdateFinancialModal";
import { InstallmentIcon } from "../../../assets/icons/InstallmentIcon";
import { InstallmentModal } from "../components/InstallmentModal";

enum FinancialStatus {
  OPEN = "OPEN",
  PENDING = "PENDING",
  PAID = "PAID",
}

const statusStyles: Record<
  FinancialStatus,
  { color: string; borderColor: string; textColor: string; text: string }
> = {
  [FinancialStatus.OPEN]: {
    color: "#00BCFF1A",
    borderColor: "#00BCFF",
    textColor: "#00BCFF",
    text: "Aberto",
  },
  [FinancialStatus.PENDING]: {
    color: "#F5222D33",
    borderColor: "#F5222D",
    textColor: "#F5222D",
    text: "Atrasado",
  },
  [FinancialStatus.PAID]: {
    color: "#4ECB731A",
    borderColor: "#4ECB73",
    textColor: "#4ECB73",
    text: "Liquidado",
  },
};

export async function getAllFinancial(page = 1, perPage = 1000, q?: any) {
  return await api.get<IPagination<Financial>>(`/financials`, {
    params: {
      page,
      perPage,
      ...q,
    },
  });
}

interface ReceiptProps {
  load: boolean;
  handleRefreshData: () => void;
}

const Receipt = ({ load, handleRefreshData }: ReceiptProps) => {
  const { modal, message } = App.useApp();

  const [showInstallmentModal, setShowInstallmentModal] = useState(false);
  const [financial, setFinancial] = useState<Financial[]>();
  const [installment, setInstallment] = useState<Financial>();
  const [filter, setFilter] = useState();
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const handleShowInstallmentModal = (record?: Financial) => {
    setInstallment(record);
    setShowInstallmentModal(true);
  };

  const handleCloseInstallmentModal = () => {
    setShowInstallmentModal(false);
    setInstallment(undefined);
  };

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      await getAllFinancial(page, pageSize, filter)
        .then(({ data }) => {
          const filteredData = data?.data.filter(
            (item) => item.item.type === "RECEIPT"
          );
          setFinancial(filteredData);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [filter]
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, meta.per_page, handleChangePage]);

  const { data, isOpen, handleCloseModal, handleOpenModal } =
    useModal<Financial>(handleRefreshData);

  const onDeleteReceivables = async (record: Financial) => {
    return modal.confirm({
      title: "Deseja deletar esta conta a receber?",
      content: <>A conta será excluída permanentemente!</>,
      okText: "Deletar",
      okButtonProps: {
        type: "primary",
        danger: true,
      },
      cancelText: "Cancelar",
      cancelButtonProps: {
        type: "default",
        danger: true,
      },
      onOk: async () => {
        await api
          .delete(`/financials/${record.item.id}`)
          .catch(() => message.error("Ops! Algo deu errado!"))
          .then(handleRefreshData);
      },
    });
  };

  const actions: ActionFunction<Financial> = (record) => {
    const actionsList = [];

    if (record?.item?.installment.length > 0) {
      actionsList.push({
        key: "1",
        icon: <InstallmentIcon />,
        label: "Parcelas",
        onClick: () => handleShowInstallmentModal(record),
      });
    }

    actionsList.push(
      {
        key: "2",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "3",
        label: "Deletar",
        icon: <DeleteOutlined />,
        danger: true,
        onClick: () => onDeleteReceivables(record),
      }
    );

    return actionsList;
  };

  const columns: ColumnsType<Financial> = [
    {
      title: "ID",
      dataIndex: ["item", "id"],
      key: "id",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value.match(/\d/g).slice(0, 4).join("")}
          </div>
        );
      },
    },
    {
      title: "Contato",
      dataIndex: ["item", "client"],
      key: "client",
      ellipsis: true,
      render: (value, record) => {
        const { supplier } = record.item;

        return <div>{value?.name || supplier?.name || "S/N"}</div>;
      },
    },
    {
      title: "Data de Vencimento",
      dataIndex: ["item", "due"],
      key: "due",
      ellipsis: true,
      render: (due, record) => {
        const {
          item: { installment, recurrence_date },
        } = record;

        if (due) {
          if (recurrence_date) {
            return formatters.simpleDate(recurrence_date);
          }
          return formatters.simpleDate(due);
        }

        const openInstallment = installment?.find(
          (item) => item.status === "OPEN"
        );

        const allPaid = installment?.every((item) => item.status === "PAID");
        const allOpen = installment?.every((item) => item.status === "OPEN");
        const firstPending = installment?.find(
          (item) => item.status === "PENDING"
        );

        let selectedInstallment;

        if (allPaid) {
          selectedInstallment = installment[installment.length - 1];
        } else if (allOpen) {
          selectedInstallment = installment[0];
        } else if (firstPending) {
          selectedInstallment = firstPending;
        } else {
          selectedInstallment = openInstallment;
        }

        return formatters.simpleDate(selectedInstallment?.due);
      },
    },
    {
      title: "Valor",
      dataIndex: ["item", "value"],
      key: "value",
      ellipsis: true,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>{formatters.currency(value)}</div>
        );
      },
    },
    {
      title: "Cobrança",
      dataIndex: ["item", "payment_type"],
      key: "payment_type",
      ellipsis: true,
      render: (paymentType: string, record) => {
        const paymentTypesMap: { [key: string]: string } = {
          SINGLE: "Único",
          RECURRING: "Recorrente",
          INSTALLMENT: "Parcelado",
        };
        return (
          <div>
            <div>{paymentTypesMap[paymentType] || paymentType}</div>
            {paymentType === "INSTALLMENT" && (
              <div style={{ marginTop: 5 }}>
                <div style={{ width: "150px" }}>
                  {" "}
                  <Progress
                    percent={
                      (record.paidInstallments / record.totalInstallments) * 100
                    }
                    size="small"
                    strokeWidth={10}
                    status="active"
                    showInfo={false}
                  />
                  {`${record.paidInstallments}/${record.totalInstallments}x`}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: ["item", "status"],
      key: "status",
      render: (status: keyof typeof statusStyles) => {
        const { color, borderColor, textColor, text } = statusStyles[
          status
        ] || {
          color: "#ddd",
          borderColor: "#aaa",
          textColor: "#333",
          text: status,
        };

        return (
          <Tag
            style={{
              backgroundColor: color,
              borderColor: borderColor,
              color: textColor,
              borderWidth: "1.5px",
              borderStyle: "solid",
              borderRadius: "3px",
              padding: "3px 10px",
              textTransform: "uppercase",
            }}
          >
            {text}
          </Tag>
        );
      },
    },
  ];

  const handleFilterData = (values: any) => {
    setFilter(values);
  };

  return (
    <>
      <Content width="calc(100% - 48px)" margin>
        <FilterFinancialList onSubmit={handleFilterData} />

        <UpdateFinancialModal
          isModalOpen={isOpen}
          onClose={handleCloseModal}
          data={data}
          onRefreshData={handleRefreshData}
        />

        <InstallmentModal
          data={installment}
          isModalOpen={showInstallmentModal}
          onClose={handleCloseInstallmentModal}
          onRefreshData={handleRefreshData}
        />

        <ActionTable
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 30, 50],
            current: meta.current_page,
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
          }}
          actions={actions}
          loading={loading}
          columns={columns}
          dataSource={financial}
        />
      </Content>
    </>
  );
};

export { Receipt };

import { FC, useEffect, useState } from "react";

import {
  App,
  Button,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from "antd";

import { api } from "../../../services/api";
import { IEquipmentType } from "../../../types";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

const GridStyle = {
  display: "grid",
  gridTemplateColumns: "1fr 40px",
  width: "100%",
};

interface EquipmentTypeModalProps {
  onClose: () => void;
  onRefreshPage?: () => void;
  isModalOpen: boolean;
  equipmentType?: IEquipmentType;
}

const EquipmentTypeModal: FC<EquipmentTypeModalProps> = ({
  onClose,
  onRefreshPage,
  isModalOpen,
  equipmentType,
}) => {
  const { message } = App.useApp();
  const [form] = Form.useForm<IEquipmentType>();
  const [loading, setLoading] = useState(false);
  console.log(equipmentType);

  useEffect(() => {
    if (equipmentType) {
      form.setFieldsValue(equipmentType);
    }
  }, [form, equipmentType]);

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose();
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
          name: values.name.toUpperCase(),
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }
    console.log(values);
    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `Tipo de equipamento ${
            data.config.method === "put" ? "atualizado" : "criado"
          } com sucesso!`
        );
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
        if (onRefreshPage) {
          onRefreshPage();
        }
      });
  };

  const handleSubmitForm = async (values: IEquipmentType) => {
    if (equipmentType) {
      return await api.put<IEquipmentType>(
        `/equipment-types/${equipmentType.id}`,
        values
      );
    }
    return await api.post<IEquipmentType>(`/equipment-types/`, values);
  };

  return (
    <Modal
      title={`${equipmentType ? "Editar" : "Adicionar"} tipo de equipamento`}
      open={isModalOpen}
      centered
      width={600}
      onCancel={handleCloseModal}
      footer={[
        <Button key="back" onClick={handleCloseModal} danger disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {equipmentType ? "Salvar" : "Adicionar"}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="equipment_type_form_modal">
          <Form.Item
            label="Nome:"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor insira o nome do Tipo de Equipamento!",
              },
            ]}
          >
            <Input placeholder="Insira o nome" />
          </Form.Item>
          <Text strong>
            Campos para cadastro de equipamento{" "}
            <Tooltip title="Ao selecionar o campo, será necessário informá-lo no momento do cadastro deste tipo de equipamento.">
              <QuestionCircleOutlined />
            </Tooltip>
          </Text>
          <Flex vertical gap="middle" style={{ paddingTop: "1rem" }}>
            <div style={GridStyle}>
              <Flex vertical gap="small">
                <Text strong>Marca</Text>
                <Text>Informa qual a marca do equipamento.</Text>
              </Flex>
              <Form.Item name="brand" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
            <div style={GridStyle}>
              <Flex vertical gap="small">
                <Text strong>Modelo</Text>
                <Text>Informa o modelo do equipamento.</Text>
              </Flex>
              <Form.Item name="model" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>

            <div style={GridStyle}>
              <Flex vertical gap="small">
                <Text strong>Número de série</Text>
                <Text>Informa o número de série do equipamento</Text>
              </Flex>
              <Form.Item name="serial" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
            <div style={GridStyle}>
              <Flex vertical gap="small">
                <Text strong>Fluído refrigerante</Text>
                <Text>
                  Informa o tipo de fluído refrigerante do equipamento. (Ex:
                  R14, R410A).
                </Text>
              </Flex>
              <Form.Item name="gas" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
            <div style={GridStyle}>
              <Flex vertical gap="small">
                <Text strong>Capacidade (em litros)</Text>
                <Text>Informa a capacidade em litros do equipamento.</Text>
              </Flex>
              <Form.Item name="capatity" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
            <div style={GridStyle}>
              <Flex vertical gap="small">
                <Text strong>Ventilação</Text>
                <Text>
                  Informa o tipo de ventilação do equipamento. (Ex: Hi-Waal,
                  Cassete).
                </Text>
              </Flex>
              <Form.Item name="ventilation" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
            <div style={GridStyle}>
              <Flex vertical gap="small">
                <Text strong>BTUs</Text>
                <Text>Informa a quantidade de BTUs do equipamento.</Text>
              </Flex>
              <Form.Item name="btus" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
            <div style={GridStyle}>
              <Flex vertical gap="small">
                <Text strong>Voltagem</Text>
                <Text>
                  Informa a voltagem do equipamento. (Ex: 127v, 220v).
                </Text>
              </Flex>
              <Form.Item name="voltage" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
            <div style={GridStyle}>
              <Flex vertical gap="small">
                <Text strong>Data de fabricação</Text>
                <Text>Informa a data de fabricação do equipamento.</Text>
              </Flex>
              <Form.Item name="fabricate" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
          </Flex>
          <Divider style={{ borderColor: "#D9D9D9" }} />

          <Text strong>PMOC</Text>
          <div style={GridStyle}>
            <Flex vertical gap="small">
              <Text strong>Habilitar PMOC</Text>
              <Text>
                Permitir que os equipamentos sejam listados no módulo de PMOC.
              </Text>
            </Flex>
            <Form.Item name="isNeedPmoc" valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export { EquipmentTypeModal };

import axios from "axios";
import { TOKEN_API, api } from "./api";

const reports = async (url: string, filter: any, horizontal?: boolean) => {
  const baseUrl = new URL(
    "reports/" + url,
    process.env.REACT_APP_REPORTS
  ).toString();

  const size = horizontal ? "width=1123,height=800" : "width=810,height=800";

  if (process.env.REACT_APP_NODE_ENV === "development") {
    api
      .get("reports/" + url, { params: filter })
      .then((res) => console.log(res.data))
      .catch((e) => console.log(e));
  }

  await axios
    .get(baseUrl, {
      params: filter,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
      },
      paramsSerializer: {
        indexes: true,
      },
    })
    .then((value) => {
      var _url = window.URL.createObjectURL(value.data);
      if (_url) {
        window.open(_url, "Axios data", size)?.focus();
      }
    })
    .catch((err) => {
      console.log(err)
    });
};

export { reports };

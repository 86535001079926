import { Avatar, Button, Dropdown, Menu, MenuProps, theme } from "antd";
import { Header as AntHeader } from "antd/es/layout/layout";
import {
  BankOutlined,
  DollarOutlined,
  LoginOutlined,
  PlusCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useColorMode } from "../../contexts/ColorModeContext";

import Image from "../../assets/logo-arcke.svg";
import ImageDark from "../../assets/logo-dark.svg";

import {
  ContractsIcon,
  DashboardIcon,
  PmocIcon,
  ReportsIcon,
  ServiceOrderIcon,
} from "../../assets/icons";
import { DarkTheme } from "../../assets/icons/DarkTheme";

const Header = () => {
  const { selectedTheme, handleChangeColorMode } = useColorMode();
  const { pathname } = useLocation();

  const {
    token: { colorBgBase, colorBgContainer, boxShadowTertiary },
  } = theme.useToken();

  const { signOut, user } = useAuth();
  const navigate = useNavigate();

  const onClick: MenuProps["onClick"] = (e) => {
    if (e.keyPath.reverse()[0] === "reports") {
      return navigate(e.keyPath?.toString().replaceAll(",", "/"), {
        replace: true,
      });
    }
    return navigate(e.key, { replace: true });
  };

  const userMenu: MenuProps["items"] = [
    {
      label: "Organização",
      key: "organization",
      onClick: onClick,
      icon: <BankOutlined />,
    },
    {
      key: "signOut",
      label: "Sair",
      icon: <LoginOutlined />,
      onClick: signOut,
      danger: true,
    },
  ];

  return (
    <AntHeader
      style={{
        backgroundColor: colorBgContainer,
        padding: "0px",
        paddingLeft: "16px",
        height: "48px",
        lineHeight: "48px",
        width: "100%",
        boxShadow: boxShadowTertiary,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "fixed",
        zIndex: 1,
      }}
    >
      <style type="text/css">{`
        body {
            background-color: ${colorBgBase};
        }
    `}</style>
      <div style={{ width: "100%" }}>
        <div className="logo" style={{ height: "46px" }}>
          <img
            src={selectedTheme === "light" ? ImageDark : Image}
            alt="Arke-logo"
            style={{ objectFit: "cover", width: "120px" }}
          />
        </div>
        <Menu
          onClick={onClick}
          mode="horizontal"
          items={[
            {
              label: "Dashboard",
              key: "dashboard",
              icon: <DashboardIcon />,
            },
            {
              label: "Atendimento",
              key: "orders",
              onTitleClick: (e) => navigate(e.key),
              icon: <ServiceOrderIcon />,
            },
            {
              label: "Contrato",
              key: "contracts",
              icon: <ContractsIcon />,
            },
            {
              label: "Cadastro",
              key: "records",
              icon: <PlusCircleOutlined />,
              children: [
                {
                  label: "Geral",
                  key: "geral",
                  children: [
                    {
                      label: "Marca",
                      key: "brands",
                    },
                    {
                      label: "Grupo de serviço",
                      key: "service-types",
                    },
                    {
                      label: "Tipo de equipamento",
                      key: "equipment-types",
                    },
                    {
                      label: "Equipamento",
                      key: "equipments",
                    },
                  ],
                },
                {
                  label: "Financeiro",
                  key: "finance",
                  children: [
                    {
                      label: "Conta de operação",
                      key: "transaction-account",
                    },
                    {
                      label: "Forma de pagamento",
                      key: "payment-method",
                    },
                    { label: "Plano de contas", key: "chart-of-accounts" },
                    {
                      label: "Centro de custo",
                      key: "cost-center",
                    },
                  ],
                },
                {
                  label: "Item de serviço",
                  key: "items",
                },
                {
                  label: "PMOC",
                  key: "pmoc-records",
                  children: [
                    {
                      label: "Atividade de PMOC",
                      key: "activities",
                    },
                    {
                      label: "Responsável técnico",
                      key: "technical-manager",
                    },
                  ],
                },
                {
                  label: "Cliente",
                  key: "clients",
                },
                {
                  label: "Fornecedor",
                  key: "suppliers",
                },
                {
                  label: "Produto",
                  key: "product",
                  children: [
                    {
                      label: "Produtos",
                      key: "products",
                    },
                    {
                      label: "Grupo de produtos",
                      key: "product-group",
                    },
                    {
                      label: "Tabela de preços",
                      key: "price-table",
                    },
                  ],
                },
                {
                  label: "Usuário",
                  key: "users",
                },
              ],
            },
            {
              label: "Financeiro",
              key: "financial",
              icon: <DollarOutlined />,
            },
            {
              label: "Planejamento",
              key: "plans",
              icon: <PmocIcon />,
              children: [
                {
                  label: "PMOC",
                  key: "pmoc",
                },
                {
                  label: "Checklist",
                  key: "checklists",
                },
              ],
            },
            {
              label: "Relatórios",
              key: "reports",
              icon: <ReportsIcon />,
              children: [
                {
                  label: "Comissão",
                  key: "commission",
                  children: [
                    {
                      label: "Comissão Simplificado",
                      key: "simplified-commission",
                    },
                  ],
                },
                {
                  label: "Contratos",
                  key: "contract-reports",
                  children: [
                    {
                      label: "Saldo de Itens por Contrato",
                      key: "item-balance",
                    },
                    {
                      label: "Saldo de Contrato",
                      key: "contract-balance",
                    },
                    {
                      label: "Vecimento de Contratos",
                      key: "contract-expiration",
                    },
                  ],
                },
                {
                  label: "Financeiros",
                  key: "financial-reports",
                  children: [
                    {
                      label: "Faturamento de Serviços por Local",
                      key: "service-billing",
                    },
                    {
                      label: "Faturamento por Contrato",
                      key: "contract-billing",
                    },
                    // {
                    //   label: "Vecimento de Contratos",
                    //   key: "contract-expirationa",
                    // },
                  ],
                },
                {
                  label: "Serviços",
                  key: "services",
                  children: [
                    {
                      label: "Ordens de Serviços por Status",
                      key: "orders-status",
                    },
                    {
                      label: "Itens de Serviços Detalhados",
                      key: "detailed-items",
                    },
                    {
                      label: "Itens de Serviços Agrupados",
                      key: "grouped-items",
                    },
                  ],
                },
                {
                  label: "Relatório de PMOC",
                  key: "pmocs",
                },
                {
                  label: "Planilha de Atendimentos",
                  key: "worksheet",
                },
              ],
            },
          ]}
          selectedKeys={pathname.split("/")}
        />
      </div>

      <div
        style={{
          paddingRight: "16px",
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Button
          type="link"
          style={{ display: "flex", alignItems: "center", padding: "0" }}
          onClick={handleChangeColorMode}
        >
          <DarkTheme color={selectedTheme === "light" ? "#000" : "#fff"} />
        </Button>
        <Dropdown
          trigger={["click"]}
          menu={{ items: userMenu }}
          placement="bottomLeft"
        >
          <Avatar
            icon={<UserOutlined />}
            style={{ cursor: "pointer" }}
            src={user?.account?.image}
          />
        </Dropdown>
      </div>
    </AntHeader>
  );
};

export { Header };

import { FC, useEffect, useState } from "react";

import { Button, Form, Input, message as Message, Modal, Spin } from "antd";

import { api } from "../../../services/api";
import { ICategory, IProduct, ModalProps } from "../../../types";

const ProductGroupModal: FC<ModalProps<ICategory>> = ({
  onClose,
  onRefreshData,
  isModalOpen,
  data: group,
}) => {
  const [message, context] = Message.useMessage();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      if (group) {
        form.setFieldsValue({
          ...group,
        });
      }
    }
  }, [form, isModalOpen, group]);

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose();
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }

    handleSubmitForm({ ...values })
      .then((data) => {
        message.success(
          `Item ${
            data.config.method === "put" ? "atualizado" : "criado"
          } com sucesso!`
        );
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
        if (onRefreshData) {
          onRefreshData();
        }
      });
  };

  const handleSubmitForm = async (values: IProduct) => {
    if (group) {
      return await api.put(`/categories/${group.id}`, values);
    }
    return await api.post(`/categories`, values);
  };

  return (
    <Modal
      title={`${group ? "Editar" : "Cadastrar"} grupo`}
      open={isModalOpen}
      centered
      onCancel={handleCloseModal}
      footer={[
        <Button key="back" onClick={handleCloseModal} danger disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      {context}
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="product_group_form_modal">
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor insira o nome!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { ProductGroupModal };

import { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import { App, Button, Tag } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { useModal } from "../../../hooks";
import { IMeta, IPagination, IPayment } from "../../../types";
import { api } from "../../../services/api";

import { PageHeader } from "../../../components/PageHeader";
import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { Content } from "../../../components/Content";
import { PaymentMethodModal } from "../components/PaymentMethodModal";

export async function getPaymentMethod(page = 1, perPage = 10) {
  return await api.get<IPagination<IPayment>>(
    `/financials/payment-methods/all/`,
    {
      params: {
        page,
        perPage,
      },
    }
  );
}

const PaymentMethod = () => {
  const { modal, message } = App.useApp();

  const [payment, setPayment] = useState<IPayment[]>([]);
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      await getPaymentMethod(page, pageSize)
        .then(({ data }) => {
          setPayment(data?.data);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, meta.per_page, handleChangePage]);

  const handleRefreshData = () => {
    setLoad(!load);
  };

  const { data, isOpen, handleCloseModal, handleOpenModal } =
    useModal<IPayment>(handleRefreshData);

  const actions: ActionFunction<IPayment> = (record) => {
    return [
      {
        key: "2",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => onDeleteItem(record),
      },
    ];
  };

  const columns: ColumnsType<IPayment> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value.match(/\d/g).slice(0, 4).join("")}
          </div>
        );
      },
      width: 80,
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Banco",
      dataIndex: "bankAccount",
      key: "bankAccount",
      render: (bankAccount) => bankAccount?.name,
    },
    {
      title: "Tipo de pagamento",
      dataIndex: "payment_type",
      key: "payment_type",
    },
    {
      title: "Liquidação automática",
      dataIndex: "automatic_liquidation",
      key: "automatic_liquidation",
      render: (text) =>
        text === true ? (
          <Tag color={"green"}>SIM</Tag>
        ) : (
          <Tag color={"red"}>NÃO</Tag>
        ),
    },
    ActionColumn(actions),
  ];

  const onDeleteItem = async (record: IPayment) => {
    return modal.confirm({
      title: "Deseja deletar o pagemento?",
      content: <>O pagamento será excluído permanentemente!</>,
      onOk: () =>
        api
          .delete(`/financials/payment-methods/${record.id}`)
          .catch(() => message.error(`Algo inesperado ocorreu!`))
          .then(handleRefreshData),
    });
  };

  return (
    <>
      <PageHeader
        title="Forma de pagamento"
        backButton={false}
        actions={[
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => handleOpenModal()}
          >
            Cadastrar pagamento
          </Button>,
        ]}
        crumb
      />

      <PaymentMethodModal
        isModalOpen={isOpen}
        onClose={handleCloseModal}
        data={data}
        onRefreshData={handleRefreshData}
      />

      <Content width="calc(100% - 48px)" margin>
        <Table
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 30, 50],
            current: meta.current_page,
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
          }}
          size={"small"}
          loading={loading}
          rowKey={(record: IPayment) => record.id}
          columns={columns}
          dataSource={payment}
        />
      </Content>
    </>
  );
};

export { PaymentMethod };

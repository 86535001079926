import { FC } from "react";
import { Col, Form, Input } from "antd";
import { ExpandableFilter } from "../../../components/ExpandableFilter";

interface FilterTechnicalManagerProps {
  onSubmit?: any;
}

interface FilterTechnicalManagerType {
  name?: string;
  document?: string;
  creaOrCft?: string;
}

const FilterTechnicalManager: FC<FilterTechnicalManagerProps> = ({
  onSubmit,
}) => {
  const [form] = Form.useForm<FilterTechnicalManagerType>();

  const fields = [
    <Col span={9} key={1}>
      <Form.Item name={"name"} label={"Nome"}>
        <Input allowClear placeholder="Insira um nome" />
      </Form.Item>
    </Col>,
    <Col span={6} key={1}>
      <Form.Item name={"document"} label={"CPF | CNPJ"}>
        <Input allowClear placeholder="Insira um CPF ou CNPJ" />
      </Form.Item>
    </Col>,
    <Col span={7} key={1}>
      <Form.Item name={"creaOrCft"} label={"Registro (CREA/CFT)"}>
        <Input allowClear />
      </Form.Item>
    </Col>,
  ];

  const onFinish = (values: FilterTechnicalManagerType) => {
    if (onSubmit) {
      onSubmit({ ...values });
    }
  };

  const onClear = () => {
    onSubmit();
  };

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterTechnicalManager };

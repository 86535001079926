import {
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  Upload,
  UploadProps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { formatters, normFile, parsers } from "../../../../../utils";
import { InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ICategory, ISupplier } from "../../../../../types";
import {
  getAllCategory,
  getSuppliers,
} from "../../../../../services/repositories";

const { Option } = Select;

export const unitTypes = [
  { label: "Unidade", value: "UN" },
  { label: "Caixa", value: "CX" },
  { label: "Kit", value: "KIT" },
  { label: "Barra", value: "BR" },
  { label: "Metro", value: "M1" },
  { label: "Metro Quadrados", value: "M2" },
  { label: "Metro Cubicos", value: "M3" },
  { label: "Galão", value: "GL" },
  { label: "Hora", value: "HRS" },
  { label: "Kilo", value: "KG" },
  { label: "Litro", value: "LT" },
  { label: "Serviço", value: "SERV" },
  { label: "Tonelada", value: "TON" },
  { label: "Rolo", value: "ROLO" },
  { label: "Produto", value: "PC" },
];

interface ProductFormProps {
  props: UploadProps;
}

const ProductForm = ({ props }: ProductFormProps) => {
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [productGroup, setProductGroup] = useState<ICategory[]>([]);

  useEffect(() => {
    const getData = async () => {
      await getSuppliers(1, 1000).then(({ data }) => {
        setSuppliers(data);
      });
      await getAllCategory(1, 1000).then(({ data }) => {
        setProductGroup(data.data);
      });
    };
    getData();
  }, []);

  return (
    <>
      <Form.Item
        label="Nome"
        name="name"
        rules={[
          {
            required: true,
            message: "Por favor insira o nome do Produto.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Descrição" name="description">
        <TextArea
          style={{ resize: "none" }}
          rows={5}
          showCount
          maxLength={255}
        />
      </Form.Item>
      <Form.Item label="Código de barra" name="barCode">
        <Input />
      </Form.Item>
      <Form.Item
        label="Grupo de produto"
        name="categoryId"
        rules={[
          {
            required: true,
            message: "Por favor selecione um Grupo de produto",
          },
        ]}
      >
        <Select optionFilterProp="children" showSearch allowClear>
          {productGroup.map((group) => {
            return (
              <Option key={group.id} value={group.id}>
                {group.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Controla estoque?"
        name="stockControl"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Emite nota fiscal?"
        name="issueInvoice"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Tipo de unidade"
        name="inputUnit"
        rules={[
          {
            required: true,
            message: "Por favor selecione o a unidade do Produto.",
          },
        ]}
      >
        <Select
          optionFilterProp="children"
          showSearch
          allowClear
          options={unitTypes.map((unitType) => {
            return {
              value: unitType.value,
              label: `${unitType.label} | ${unitType.value}`,
            };
          })}
        />
      </Form.Item>
      <p style={{ padding: "6px 0" }}>Converter unidade</p>
      <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
        <Form.Item label="Entrada">
          <InputNumber min={1} defaultValue={1} disabled />
        </Form.Item>
        <Form.Item name="inputUnit" label=" ">
          <Select
            optionFilterProp="children"
            style={{ width: "200px" }}
            showSearch
            allowClear
            options={unitTypes.map((unitType) => {
              return {
                value: unitType.value,
                label: `${unitType.label} | ${unitType.value}`,
              };
            })}
          />
        </Form.Item>
        equivale a
        <Form.Item name="conversionFactor" label="Saída">
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item name="outputUnit" label=" ">
          <Select
            optionFilterProp="children"
            style={{ width: "200px" }}
            showSearch
            allowClear
            options={unitTypes.map((unitType) => {
              return {
                value: unitType.value,
                label: `${unitType.label} | ${unitType.value}`,
              };
            })}
          />
        </Form.Item>
      </div>
      <Form.Item name="costPrice" label="Preço de custo">
        <InputNumber
          formatter={formatters.currency}
          min={0.1}
          parser={parsers.currency}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        name="cost"
        label="Preço de venda"
        rules={[
          {
            required: true,
            message: "Por favor, insira o valor do Produto.",
          },
        ]}
      >
        <InputNumber
          formatter={formatters.currency}
          min={0.1}
          parser={parsers.currency}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        name="minimumStock"
        label="Estoque Mínimo"
        rules={[
          {
            required: true,
            message: "Por favor, insira o estoque mínimo Produto.",
          },
        ]}
      >
        <InputNumber min={1} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="maximumStock" label="Estoque Máximo">
        <InputNumber min={1} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="stock"
        label="Estoque atual"
        rules={[
          {
            required: true,
            message: "Por favor, insira o estoque do Produto.",
          },
        ]}
      >
        <InputNumber min={1} style={{ width: "100%" }} />
      </Form.Item>
      <p style={{ padding: "6px 0" }}>Foto do produto</p>
      <Form.Item
        name="dragger"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        noStyle
      >
        <Upload.Dragger name="file" {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click ou arraste o arquivo para essa area de upload
          </p>
          <p className="ant-upload-hint">
            Suporte para upload único ou em massa.
          </p>
        </Upload.Dragger>
      </Form.Item>
      <Form.Item
        label="Fornecedores"
        name="suppliers"
        style={{ paddingTop: "16px" }}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          mode="multiple"
          showSearch
          allowClear
          placeholder="Selecione os fornecedores"
        >
          {suppliers.map((supplier) => {
            return (
              <Option key={supplier.id} value={supplier.id}>
                {supplier.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
};

export { ProductForm };

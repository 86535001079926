import { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import { App, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { PageHeader } from "../../../components/PageHeader";
import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { Content } from "../../../components/Content";

import { useModal } from "../../../hooks";
import { IMeta, IPagination, ITechnicalManager } from "../../../types";
import { api } from "../../../services/api";

import { TechnicalManagerModal } from "../components/TechnicalManagerModal";
import { FilterTechnicalManager } from "../components/FilterTechnicalManager";

export async function getTechnicalManager(page = 1, perPage = 10, q: any) {
  return await api.get<IPagination<ITechnicalManager>>(`/technical-manager`, {
    params: {
      page,
      perPage,
      ...q,
    },
  });
}

const TechnicalManager = () => {
  const { modal, message } = App.useApp();

  const [technicalManager, setTechnicalManager] = useState<ITechnicalManager[]>(
    []
  );
  const [filter, setFilter] = useState();
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      await getTechnicalManager(page, pageSize, filter)
        .then(({ data }) => {
          setTechnicalManager(data?.data);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [filter]
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, meta.per_page, handleChangePage]);

  const handleRefreshData = () => {
    setLoad(!load);
  };

  const { data, isOpen, handleCloseModal, handleOpenModal } =
    useModal<ITechnicalManager>(handleRefreshData);

    const actions: ActionFunction<ITechnicalManager> = (record) => {
      return [
        {
          key: '1',
          icon: <EditOutlined />,
          label: "Editar",
          onClick: () => handleOpenModal(record),
        },
        {
          key: '2',
          icon: <DeleteOutlined />,
          label: "Deletar",
          danger: true,
          onClick: () => onDeleteItem(record),
        },
      ];
    };
    

  const columns: ColumnsType<ITechnicalManager> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "CPF/CNPJ",
      dataIndex: "document",
      key: "document",
    },
    {
      title: "Registro (CREA/CFT)",
      dataIndex: "creaOrCft",
      key: "creaOrCft",
    },
    {
      title: "ART",
      dataIndex: "ART",
      key: "ART",
    },
    ActionColumn(actions),
  ];

  const onDeleteItem = async (record: ITechnicalManager) => {
    return modal.confirm({
      title: "Atenção!",
      content: (
        <>
          Deseja deletar esse responsável técnico?
          <br />
          <i>'{record.name}'</i>
        </>
      ),
      onOk: () =>
        api
          .delete(`/technical-manager/${record.id}`)
          .catch(() => message.error(`Algo inesperado ocorreu!`))
          .then(handleRefreshData),
    });
  };

  const handleFilterData = (values: any) => {
    setFilter(values);
  };

  return (
    <>
      <PageHeader
        title="Responsável técnico"
        backButton={false}
        actions={[
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => handleOpenModal()}
          >
            Cadastrar técnico
          </Button>,
        ]}
        crumb
      />

      <TechnicalManagerModal
        isModalOpen={isOpen}
        onClose={handleCloseModal}
        handleRefreshData={handleRefreshData}
        data={data}
      />

      <Content
        width="calc(100% - 48px)"
        margin
        style={{ padding: "24px 40px 0" }}
      >
        <FilterTechnicalManager onSubmit={handleFilterData} />
      </Content>

      <Content width="calc(100% - 48px)" margin>
        <Table
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 30, 50],
            current: meta.current_page,
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
          }}
          size={"small"}
          loading={loading}
          rowKey={(record: ITechnicalManager) => record.id}
          columns={columns}
          dataSource={technicalManager}
        />
      </Content>
    </>
  );
};

export { TechnicalManager };

import { RouteObject } from "react-router-dom";
import { PriceTable } from "./list";

export const PriceTableRouter: RouteObject = {
  path: "/price-table",
  handle: { crumb: () => "Cadastros / Produtos / Tabela de preço" },
  children: [
    {
      index: true,
      element: <PriceTable />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};

import { FC, useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, message as Message, Modal } from "antd";

import { api } from "../../../services/api";

import { ChartOfAccountSon, ModalProps } from "../../../types";

const UpdateChartOfAccountModal: FC<ModalProps<ChartOfAccountSon>> = ({
  onClose,
  data,
  isModalOpen,
  onRefreshData,
}) => {
  const [message] = Message.useMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (data) {
        form.setFieldsValue({ ...data, dre: data.dre });
      }
    };
    if (isModalOpen) {
      getData().finally(() => setLoading(false));
    }
  }, [form, data, isModalOpen]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    if (data) {
      await api
        .put(`/chart-of-accounts/${data.id}`, values)
        .then(() => {
          message.success("Plano de contas atualizado com sucesso!");
          if (onRefreshData) {
            onRefreshData();
          }
          handleCloseModal();
        })
        .catch((error) => {
          if (error.response.data.errors) {
            error.response.data.errors.forEach((err: any) =>
              message.error(err.message)
            );
          } else {
            message.error("Algo inesperado ocorreu!");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Modal
      title="Editar plano de contas"
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          disabled={loading}
          danger
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="update_chart_of_account_form_modal"
      >
        <Form.Item
          name="name"
          label="Nome"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="dre" valuePropName="checked">
          <Checkbox>Permitir que esse plano de contas apareça no DRE.</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { UpdateChartOfAccountModal };

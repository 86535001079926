import { useState, useEffect, FC, useCallback } from "react";

import { Button, Table, Tag, Image } from "antd";
import { ColumnsType } from "antd/es/table";

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import { api } from "../../../services/api";

import { Content } from "../../../components/Content";
import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
import { FilterItemList } from "../components/FilterItemList";
import { ProductModal } from "../components/ProductModal";
import { IProduct, IMeta, IPagination } from "../../../types";
import { formatters } from "../../../utils";

import fallback from "../../../assets/fallaback-image.png";

export const getProducts = async (page = 1, perPage = 10, q?: any) => {
  return await api.get<IPagination<IProduct>>(`/products`, {
    params: {
      page,
      perPage,
      ...q,
    },
  });
};

const deleteProduct = async (productId: string) => {
  return await api.delete(`/products/${productId}`);
};

const Products: FC = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [product, setProduct] = useState<IProduct>();

  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [filter, setFilter] = useState();
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      await getProducts(page, pageSize, filter)
        .then(({ data }) => {
          setProducts(data?.data);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [filter]
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, filter, meta.per_page, handleChangePage]);

  const actions: ActionFunction<IProduct> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleShowModal("update", record),
      },
      {
        key: "2",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => onDeleteItem(record),
      },
    ];
  };

  const columns: ColumnsType<IProduct> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      render: (text) => <Tag>{text}</Tag>,
      width: 80,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: 100,
      render: (text) => (
        <Image
          src={process.env.REACT_APP_ENDPOINT + text}
          height={64}
          width={64}
          style={{ objectFit: "cover" }}
          fallback={fallback}
          loading="lazy"
        />
      ),
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 300,
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      width: 300,
    },
    {
      title: "Unidade",
      dataIndex: "inputUnit",
      key: "inputUnit",
      width: 80,
    },
    {
      title: "Preço",
      dataIndex: "costPrice",
      key: "costPrice",
      render: (value) => formatters.currency(value),
    },
    {
      title: "Custo",
      dataIndex: "cost",
      key: "cost",
      render: (value) => formatters.currency(value),
    },
    {
      title: "Estoque",
      dataIndex: "stock",
      key: "stock",
    },
    ActionColumn(actions),
  ];

  const onDeleteItem = async (record: IProduct) => {
    setLoading(true);
    await deleteProduct(record.id)
      .then(() => handleRefreshData())
      .finally(() => {
        setLoading(false);
      });
  };

  const handleShowModal = (mode?: string, record?: IProduct) => {
    if (mode === "update" && record) {
      setProduct(record);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setProduct(undefined);
  };

  const handleFilterData = (values: any) => {
    setFilter(values);
  };

  const handleRefreshData = () => {
    setLoad(!load);
  };

  return (
    <>
      <PageHeader
        title="Produtos"
        backButton={false}
        actions={[
          <Button
            key={"create_product"}
            onClick={() => handleShowModal()}
            icon={<PlusOutlined />}
            type="primary"
          >
            Adicionar Produto
          </Button>,
        ]}
        crumb
      />
      <ProductModal
        onRefreshData={handleRefreshData}
        onClose={handleCloseModal}
        isModalOpen={isModalOpen}
        data={product}
      />
      <Content
        width="calc(100% - 48px)"
        margin
        style={{ padding: "24px 40px 0" }}
      >
        <FilterItemList onSubmit={handleFilterData} />
      </Content>
      <Content width="calc(100% - 48px)" margin>
        <Table
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 30, 50],
            current: meta.current_page,
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
          }}
          size={"small"}
          loading={loading}
          rowKey={(record: IProduct) => record.id}
          columns={columns}
          dataSource={products}
        />
      </Content>
    </>
  );
};

export { Products };

import { FC, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message as Message,
  Modal,
} from "antd";

import { api } from "../../../services/api";

import { IPriceTable, ModalProps } from "../../../types";

const PriceTableModal: FC<ModalProps<IPriceTable>> = ({
  onClose,
  data,
  isModalOpen,
  onRefreshData,
}) => {
  const [message] = Message.useMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (data) {
        form.setFieldsValue(data);
      }
    };
    if (isModalOpen) {
      getData().finally(() => setLoading(false));
    }
  }, [form, data, isModalOpen]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `Preço ${
            data.config.method === "put" ? "atualizado" : "cadastrado"
          } com sucesso!`
        );
        if (onRefreshData) {
          onRefreshData();
        }
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: any) => {
    if (data) {
      return await api.put(`/products/price-tables/${data.id}`, values);
    }
    return await api.post(`/products/price-tables/`, values);
  };

  return (
    <Modal
      title={`${data ? "Editar" : "Cadastrar"} preço`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          disabled={loading}
          danger
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="supplier_form_modal">
        <Form.Item
          name="name"
          label="Nome"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="profit"
          label="Lucro (%)"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { PriceTableModal };

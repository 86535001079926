import { FC, useEffect, useState } from "react";
import { Col, DatePicker, Form, Select } from "antd";

import { IClient, ISupplier } from "../../../types";
import { getClients, getSuppliers } from "../../../services/repositories";
import { ExpandableFilter } from "../../../components/ExpandableFilter";

interface FilterFinancialListProps {
  onSubmit?: any;
}

interface FilterFinancialFormInstance {
  clientId?: string;
  supplierId?: string;
  due?: string;
  billing?: string;
  status?: string;
  settled?: boolean;
}

const FilterFinancialList: FC<FilterFinancialListProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<FilterFinancialFormInstance>();
  const [clients, setClients] = useState<IClient[]>([]);
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [fieldName, setFieldName] = useState("contact");

  const handleChange = (value: string, option: any) => {
    const isClient = clients.some((client) => client.id === value);

    const newFieldName = isClient ? "clientId" : "supplierId";
    setFieldName(newFieldName);

    form.setFieldsValue({ [newFieldName]: value });
  };

  useEffect(() => {
    const getData = async () => {
      try {
        await getClients(1, 1000).then(({ data }) => {
          setClients(data);
        });
        await getSuppliers(1, 1000).then(({ data }) => {
          setSuppliers(data);
        });
      } catch (error) {
        console.error(error);
      }
    };

    getData();
  }, []);

  const fields = [
    <Col span={7} key={1}>
      <Form.Item name={fieldName} label="Contato">
        <Select
          optionFilterProp="children"
          showSearch
          allowClear
          placeholder="Selecione uma opção"
          onChange={handleChange}
        >
          {clients?.map((client) => (
            <Select.Option value={client.id} key={client.id}>
              {client.name} (Cliente)
            </Select.Option>
          ))}
          {suppliers?.map((supplier) => (
            <Select.Option value={supplier.id} key={supplier.id}>
              {supplier.name} (Fornecedor)
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>,
    <Col span={6} key={2}>
      <Form.Item name="due" label="Data de vencimento">
        <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
      </Form.Item>
    </Col>,
    <Col span={5} key={3}>
      <Form.Item name="billing" label="Cobrança">
        <Select
          optionFilterProp="children"
          showSearch
          allowClear
          placeholder="Selecione uma opção"
        >
          <Select.Option value="SINGLE">Única</Select.Option>
          <Select.Option value="INSTALLMENT">Parcelado</Select.Option>
          <Select.Option value="RECURRING">Recorrente</Select.Option>
        </Select>
      </Form.Item>
    </Col>,
    // <Col span={5} key={4}>
    //   <Form.Item name="settled" label="Liquidado">
    //     <Radio.Group>
    //       <Radio value={true}>Sim</Radio>
    //       <Radio value={false}>Não</Radio>
    //     </Radio.Group>
    //   </Form.Item>
    // </Col>,
    <Col span={6} key={5}>
      <Form.Item name="status" label="Status">
        <Select
          optionFilterProp="children"
          showSearch
          allowClear
          placeholder="Selecione uma opção"
        >
          <Select.Option value="OPEN">Aberto</Select.Option>
          <Select.Option value="PENDING">Atrasado</Select.Option>
          <Select.Option value="PAID">Liquidado</Select.Option>
        </Select>
      </Form.Item>
    </Col>,
  ];

  const onFinish = (values: FilterFinancialFormInstance) => {
    if (onSubmit) {
      onSubmit({ ...values });
    }
  };

  const onClear = () => {
    onSubmit();
  };

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterFinancialList };

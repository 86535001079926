import { FC, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message as Message,
  Modal,
  Select,
} from "antd";

import { api } from "../../../services/api";

import {
  ChartOfAccountFather,
  ChartOfAccountSon,
  IPagination,
  ModalProps,
} from "../../../types";

export async function getChartOfAccountSons(
  page: number,
  perPage: number,
  fatherId: string
) {
  return await api.get<IPagination<ChartOfAccountSon>>(
    `/chart-of-accounts/sons`,
    {
      params: {
        page,
        perPage,
        fatherId,
      },
    }
  );
}

const ChartOfAccountModal: FC<ModalProps<ChartOfAccountSon>> = ({
  onClose,
  isModalOpen,
  onRefreshData,
}) => {
  const [message] = Message.useMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [chartOfAccountFather, setChartOfAccountFather] =
    useState<ChartOfAccountFather[]>();
  const [selectedFather, setSelectedFather] = useState();
  const [chartOfAccountSons, setChartOfAccountSons] =
    useState<ChartOfAccountSon[]>();

  useEffect(() => {
    const getChartOfAccount = async () => {
      try {
        const { data } = await api.get<ChartOfAccountFather[]>(
          "/chart-of-accounts/father/"
        );
        const page = 1;
        const perPage = 1000;
        if (selectedFather) {
          await getChartOfAccountSons(page, perPage, selectedFather).then(
            ({ data }) => {
              setChartOfAccountSons(data?.data);
            }
          );
        }
        setChartOfAccountFather(data);
      } catch (error) {
        console.error(error);
      }
    };

    getChartOfAccount();
  }, [selectedFather]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        if (values.chartOfAccountSonId) {
          delete values.chartOfAccountFatherId;
        }
        return values;
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    await api
      .post(`/chart-of-accounts/`, values)
      .then(() => {
        if (onRefreshData) {
          onRefreshData();
        }
        message.success("Plano de contas cadastrado com sucesso!");
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title={`Cadastrar plano de contas`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          disabled={loading}
          danger
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="chart_of_account_form_modal"
        initialValues={{ dre: true }}
      >
        <Form.Item
          name="chartOfAccountFatherId"
          label="Plano de contas?"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            onChange={(value) => setSelectedFather(value)}
            placeholder="Selecione uma opção"
          >
            {chartOfAccountFather?.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.chartOfAccountType === "RECEIPT"
                    ? "Recebimento"
                    : "Pagamento"}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="chartOfAccountSonId" label="Conta de origem?">
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
          >
            {chartOfAccountSons?.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="Nome"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="dre" valuePropName="checked">
          <Checkbox>Permitir que esse plano de contas apareça no DRE.</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { ChartOfAccountModal };

import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";

import {
  App,
  Button,
  Select,
  Form,
  Input,
  Modal,
  Spin,
  InputNumber,
  DatePicker,
  Switch,
} from "antd";

import { api } from "../../../services/api";
import { getAllBank } from "../../../services/repositories";
import { IBank, IBankAccount, ModalProps } from "../../../types";
import { formatters, parsers } from "../../../utils";

const { Option } = Select;

const accountType = [
  "Conta Corrente",
  "Poupança",
  "Conta Salário",
  "Caixa",
  "Carteira",
  "Outros",
];

const accountTypeOptions = accountType.map((type) => ({
  value: type,
  label: type,
}));

const TransactionAccountModal: FC<ModalProps<IBankAccount>> = ({
  onClose,
  onRefreshData,
  isModalOpen,
  data: bankAccount,
}) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [bank, setBank] = useState<IBank[]>([]);

  useEffect(() => {
    const getData = async () => {
      await getAllBank(1, 1000).then(({ data }) => {
        setBank(data.data);
      });
    };
    getData();
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      if (bankAccount) {
        const balanceDate = bankAccount.balance_date
        ? dayjs(formatters.simpleDate(bankAccount.balance_date), "DD/MM/YYYY")
        : null;

        form.setFieldsValue({
          ...bankAccount,
          accountType: bankAccount.account_type,
          bankId: bankAccount.bank.id,
          agencyNumber: bankAccount.agency_number,
          accountNumber: bankAccount.account_number,
          initialBalance: bankAccount.initial_balance,
          balanceDate,
          isActive: bankAccount.is_active,
        });
      }
    }
  }, [form, isModalOpen, bankAccount]);

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose();
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `Conta de operação ${
            data.config.method === "put" ? "atualizada" : "criada"
          } com sucesso!`
        );
        if (onRefreshData) {
          onRefreshData();
        }
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitForm = async (values: IBankAccount) => {
    if (bankAccount) {
      return await api.put<IBankAccount>(
        `/financials/bank-accounts/${bankAccount?.id}`,
        values
      );
    }
    return await api.post<IBankAccount>(`/financials/bank-accounts/`, values);
  };

  return (
    <Modal
      title={`${bankAccount ? "Editar" : "Cadastrar"} operação`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      width={480}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {bankAccount ? "Salvar" : "Adicionar"}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Nome da conta"
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input placeholder="Insira um nome" />
          </Form.Item>
          <Form.Item name="accountType" label="Tipo da conta">
            <Select
              allowClear
              options={accountTypeOptions}
              placeholder="Selecione o tipo da conta"
            />
          </Form.Item>
          <Form.Item name="bankId" label="Banco">
            <Select
              optionFilterProp="children"
              showSearch
              allowClear
              placeholder="Selecione um banco"
            >
              {bank.map((bank) => {
                return (
                  <Option key={bank.id} value={bank.id}>
                    {bank.code} - {bank.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="agencyNumber" label="Número da agência">
            <Input placeholder="Digite o número da agência" />
          </Form.Item>
          <Form.Item name="accountNumber" label="Número da conta">
            <Input placeholder="Digite o número da conta" />
          </Form.Item>
          <Form.Item name="initialBalance" label="Saldo inicial">
            <InputNumber
              formatter={formatters.currency}
              min={0.1}
              parser={parsers.currency}
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item name="balanceDate" label="Data do saldo">
            <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="isActive" label="Conta ativa">
            <Switch checkedChildren="SIM" unCheckedChildren="NÃO" />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { TransactionAccountModal };

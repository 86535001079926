import { useCallback, useEffect, useState } from "react";
import { api } from "../../../services/api";
import { ICategory, IMeta } from "../../../types";
import { App, Button } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";
import { ProductGroupModal } from "../components/ProductGroupModal";
import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { ColumnsType } from "antd/es/table";
import { Table } from "antd/lib";
import { getAllCategory } from "../../../services/repositories";


const ProductGroup = () => {
  const { modal, message } = App.useApp();
  const [productGroup, setProductGroup] = useState<ICategory[]>([]);
  const [group, setGroup] = useState<ICategory>();
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      await getAllCategory(page, pageSize)
        .then(({ data }) => {
          setProductGroup(data?.data);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, meta.per_page, handleChangePage]);

  const handleRefreshData = () => {
    setLoad(!load);
  };

  const handleShowModal = (mode?: string, record?: ICategory) => {
    if (mode === "update" && record) {
      setGroup(record);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setGroup(undefined);
  };

  const onDeleteItem = async (record: ICategory) => {
    return modal.confirm({
      title: "Atenção!",
      content: (
        <>
          Deseja deletar esse grupo de produtos?
          <br />
          <i>'{record.name}'</i>
        </>
      ),
      onOk: () =>
        api
          .delete(`/categories/${record.id}`)
          .catch(() => message.error(`Algo inesperado ocorreu!`))
          .then(handleRefreshData),
    });
  };

  const actions: ActionFunction<ICategory> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleShowModal("update", record),
      },
      {
        key: "2",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => onDeleteItem(record),
      },
    ];
  };

  const columns: ColumnsType<ICategory> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.toString().padStart(4, "0")}
          </div>
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 300,
    },
    ActionColumn(actions),
  ];

  return (
    <>
      <PageHeader
        title="Grupo de produtos"
        backButton={false}
        actions={[
          <Button
            key={"create_group"}
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => handleShowModal()}
          >
            Cadastrar Grupo
          </Button>,
        ]}
        crumb
      />
      <ProductGroupModal
        onRefreshData={handleRefreshData}
        onClose={handleCloseModal}
        isModalOpen={isModalOpen}
        data={group}
      />
      <Content width="calc(100% - 48px)" margin>
        <Table
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 30, 50],
            current: meta.current_page,
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
          }}
          size={"small"}
          loading={loading}
          rowKey={(record: ICategory) => record.id}
          columns={columns}
          dataSource={productGroup}
        />
      </Content>
    </>
  );
};

export { ProductGroup };

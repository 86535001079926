import { FC, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  message as Message,
  Modal,
  Upload,
  UploadProps,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { TOKEN_API, api } from "../../../services/api";

import { ITechnicalManager, ModalProps } from "../../../types";

interface TechnicalManagerModalProps extends ModalProps<ITechnicalManager> {
  handleRefreshData?: () => void;
}

const TechnicalManagerModal: FC<TechnicalManagerModalProps> = ({
  onClose,
  data,
  handleRefreshData,
  isModalOpen,
}) => {
  const [message] = Message.useMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    if (isModalOpen) {
      const getData = async () => {
        setLoading(true);
        if (data) {
          form.setFieldsValue(data);
          const fileData = (
            await api.get(`/technical-manager/${data.id}/files`)
          ).data.data;

          const fileList = fileData.map((file: any) => ({
            uid: file.id,
            name: file.name,
            status: "done",
            url: file.url,
          }));

          form.setFieldsValue({ fileList });
        }
        setFiles([]);
      };

      getData().finally(() => setLoading(false));
    }
  }, [form, data, isModalOpen]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    setFiles([]);
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      const updatedValues = {
        ...values,
      };
      const data = await handleSubmitForm(updatedValues);
      const id = data.data.id;
      await handleSubmitFiles(files, id);
      message.success(
        `Responsável técnico ${
          data.config.method === "put" ? "atualizado" : "cadastrado"
        } com sucesso!`
      );
      handleCloseModal();
      if (handleRefreshData) {
        handleRefreshData();
      }
    } catch (error: any) {
      if (error.errorFields) {
        error.errorFields.forEach((errorField: any) =>
          errorField.errors.forEach((msg: string) => message.error(msg))
        );
      } else if (error.response?.data?.errors) {
        error.response.data.errors.forEach((err: any) =>
          message.error(err.message)
        );
      } else {
        message.error("Algo inesperado ocorreu!");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitForm = async (values: any) => {
    if (data) {
      return await api.put(`/technical-manager/${data.id}`, values);
    }
    return await api.post(`/technical-manager/`, values);
  };

  const handleSubmitFiles = async (files: any[], id: string) => {
    const validFiles = files.filter((file) => file !== null);
    return await api.post(`/technical-manager/${id}/files`, {
      files: validFiles,
    });
  };

  const deleteFile = async (technicalManagerId: string, fileId: string) => {
    try {
      await api
        .delete(`/technical-manager/${technicalManagerId}/files/${fileId}`)
        .then(() => {
          message.success("Arquivo removido com sucesso.");
        });
    } catch (error) {
      message.error("Erro ao remover o arquivo.");
    }
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    action: process.env.REACT_APP_API + `/upload`,
    data: {
      folder: "technical_manager",
    },
    headers: {
      authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
    },
    onChange(info) {
      if (info.file.status === "done") {
        setFiles((prevFiles) => {
          const newFiles = info.fileList
            .map((file) => file.response)
            .filter((file) => file !== undefined);
          return [...prevFiles, ...newFiles];
        });
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        if (info.file.response?.errors) {
          info.file.response.errors.forEach((err: any) =>
            message.error(`${info.file.name} - ${err.message}`)
          );
        } else {
          message.error(`${info.file.name} Erro ao enviar o arquivo.`);
        }
      }
    },
    onRemove(file) {
      if (data) {
        deleteFile(data.id, file.uid);
      }
      setFiles((prevFiles) => prevFiles.filter((f) => f.uid !== file.uid));
    },
    accept:
      "image/*,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    listType: "picture",
    maxCount: 5,
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      title={`${data ? "Editar" : "Cadastrar"} responsável técnico`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          disabled={loading}
          danger
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="supplier_form_modal">
        <Form.Item
          name="name"
          label="Nome"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="document"
          label="CPF / CNPJ"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Telefone"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="creaOrCft"
          label="Registro (CREA/CFT)"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="ART"
          label="ART"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Anexar documentos"
          name="fileList"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload.Dragger name="file" {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Clique ou arraste aqui para fazer upload
            </p>
            <p className="ant-upload-hint">
              Arquivos que podem ser anexados: (PDF, PNG, JPG, Word, Excel)
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { TechnicalManagerModal };

import { FC, useEffect, useState } from "react";

import {
  App,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  Switch,
} from "antd";

import { api } from "../../../services/api";
import {
  IEquipmentType,
  IItem,
  IServiceType,
  ModalProps,
} from "../../../types";
import TextArea from "antd/es/input/TextArea";
import { getEquipmentTypes } from "../../equipmentTypes/list";
import { getServiceTypes } from "../../serviceTypes/list";
import { formatters, parsers } from "../../../utils";

export const unitTypes = [
  { label: "Unidade", value: "UN" },
  { label: "Caixa", value: "CX" },
  { label: "Kit", value: "KIT" },
  { label: "Barra", value: "BR" },
  { label: "Metro", value: "M1" },
  { label: "Metro Quadrados", value: "M2" },
  { label: "Metro Cubicos", value: "M3" },
  { label: "Galão", value: "GL" },
  { label: "Hora", value: "HRS" },
  { label: "Kilo", value: "KG" },
  { label: "Litro", value: "LT" },
  { label: "Serviço", value: "SERV" },
  { label: "Tonelada", value: "TON" },
  { label: "Rolo", value: "ROLO" },
  { label: "Peça", value: "PC" },
];

const ItemModal: FC<ModalProps<IItem>> = ({
  onClose,
  isModalOpen,
  data: item,
}) => {
  const { message } = App.useApp();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [equipmentTypes, setEquipmentTypes] = useState<IEquipmentType[]>([]);
  const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);

  useEffect(() => {
    const getData = async () => {
      await getEquipmentTypes(1, 1000).then(({ data }) => {
        setEquipmentTypes(data.data);
      });
      await getServiceTypes(1, 1000).then(({ data }) => {
        setServiceTypes(data.data);
      });
    };
    if (isModalOpen) {
      getData().then(() => {
        if (item) {
          form.setFieldsValue({
            ...item,
            serviceTypes: item.serviceTypes.map((type) => type.id),
            equipmentTypes: item.equipmentTypes.map((type) => type.id),
          });
        }
      });
    }
  }, [form, isModalOpen, item]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
          name: values.name.toUpperCase(),
          description: values.description.toUpperCase(),
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }

    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `Item ${
            data.config.method === "put" ? "atualizado" : "criado"
          } com sucesso!`
        );
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: IItem) => {
    if (item) {
      return api.put<IItem>(`/items/${item.id}`, values);
    }
    return api.post<IItem>(`/items`, values);
  };

  return (
    <Modal
      title={`${item ? "Editar" : "Adicionar"} item`}
      style={{ top: 24 }}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {item ? "Salvar" : "Adicionar"}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="unit_form_modal">
          <Form.Item
            label="Nome:"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor, insira o nome do item!",
              },
            ]}
          >
            <Input placeholder="Insira o nome" />
          </Form.Item>
          <Form.Item label="Descrição:" name="description">
            <TextArea
              style={{ resize: "none" }}
              rows={5}
              showCount
              maxLength={255}
              placeholder="Insira a descrição"
            />
          </Form.Item>
          <Form.Item
            label="Unidade:"
            name="unit"
            rules={[
              {
                required: true,
                message: "Por favor, selecione o a unidade do item!",
              },
            ]}
          >
            <Select
              optionFilterProp="children"
              showSearch
              allowClear
              options={unitTypes.map((unitType) => {
                return {
                  value: unitType.value,
                  label: `${unitType.label} | ${unitType.value}`,
                };
              })}
              placeholder="Seleciona a unidade do item"
            />
          </Form.Item>
          <Form.Item
            label="Tipos de Equipamento:"
            name="equipmentTypes"
            rules={[
              {
                required: true,
                message:
                  "Por favor, selecione ao menos um tipo de equipamento!",
              },
            ]}
          >
            <Select
              mode={"multiple"}
              optionFilterProp="children"
              showSearch
              allowClear
              options={equipmentTypes.map((equipmentType) => {
                return { value: equipmentType.id, label: equipmentType.name };
              })}
              placeholder="Selecione o tipo de equipamento"
            />
          </Form.Item>
          <Form.Item
            label="Tipo de Serviço:"
            name="serviceTypes"
            rules={[
              {
                required: true,
                message: "Por favor, selecione ao menos um tipo de serviço!",
              },
            ]}
          >
            <Select
              mode={"multiple"}
              optionFilterProp="children"
              showSearch
              allowClear
              options={serviceTypes.map((serviceType) => {
                return { value: serviceType.id, label: serviceType.name };
              })}
              placeholder="Selecione o tipo de serviço"
            />
          </Form.Item>
          <Form.Item
            name="value"
            label="Preço:"
            rules={[
              {
                required: true,
                message: "Por favor, insira o valor do Produto.",
              },
            ]}
          >
            <InputNumber
              formatter={formatters.currency}
              min={0.1}
              parser={parsers.currency}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item name="cost_price" label="Preço de custo:">
            <InputNumber
              formatter={formatters.currency}
              min={0.1}
              parser={parsers.currency}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="cost"
            label="Custo:"
            rules={[
              {
                required: true,
                message:
                  "Por favor, insira o valor de custo do Produto Produto.",
              },
            ]}
          >
            <InputNumber
              formatter={formatters.currency}
              min={0.1}
              parser={parsers.currency}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item label="Avulso?" name="isDetached" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item
            label="Consumível?"
            name="isConsumible"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { ItemModal };

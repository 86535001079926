import { FC } from "react";
import dayjs from "dayjs";
import { Descriptions, DescriptionsProps, Modal } from "antd";

import { IBankAccount, ModalProps } from "../../../types";
import { formatters } from "../../../utils";

const TransactionAccountDetails: FC<ModalProps<IBankAccount>> = ({
  onClose,
  isModalOpen,
  data: bankAccount,
}) => {
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Nome da conta",
      children: bankAccount?.name,
    },
    {
      key: "2",
      label: "Tipo da conta",
      children: bankAccount?.account_type,
    },
    {
      key: "3",
      label: "Banco",
      children: bankAccount?.bank.name,
    },
    {
      key: "4",
      label: "Número da agência",
      children: bankAccount?.agency_number,
    },
    {
      key: "5",
      label: "Número da conta",
      children: bankAccount?.account_number,
    },
    {
      key: "6",
      label: "Saldo inicial",
      children: (
        <div style={{ color: "#3030DA" }}>
          {formatters.currency(bankAccount?.initial_balance.toString())}
        </div>
      ),
    },
    {
      key: "7",
      label: "Data do saldo:",
      children: dayjs(bankAccount?.balance_date).format("DD/MM/YYYY") || "N/A",
    },
    {
      key: "8",
      label: "Saldo atual",
      children: (
        <div style={{ color: "#3030DA" }}>
          {formatters.currency(bankAccount?.current_balance.toString())}
        </div>
      ),
    },
    {
      key: "9",
      label: "Conta ativa",
      children: bankAccount?.is_active ? "Sim" : "Não",
    },
  ];

  return (
    <Modal
      title="Detalhar operação"
      open={isModalOpen}
      onCancel={() => onClose()}
      onOk={() => onClose()}
      width={480}
    >
      <Descriptions layout="vertical" column={2} items={items} />
    </Modal>
  );
};

export { TransactionAccountDetails };

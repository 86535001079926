import { useCallback, useEffect, useState } from "react";
import { App, Button } from "antd";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import { useModal } from "../../../hooks";
import { IMeta, IPagination, IPriceTable } from "../../../types";
import { api } from "../../../services/api";

import { PageHeader } from "../../../components/PageHeader";
import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { Content } from "../../../components/Content";
import { PriceTableModal } from "../components/PriceTableModal";

export async function getPriceTable(page = 1, perPage = 10) {
  return await api.get<IPagination<IPriceTable>>(`/products/price-tables`, {
    params: {
      page,
      perPage,
      name: "",
    },
  });
}

const PriceTable = () => {
  const { modal, message } = App.useApp();

  const [priceTable, setPriceTable] = useState<IPriceTable[]>([]);
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      await getPriceTable(page, pageSize)
        .then(({ data }) => {
          setPriceTable(data?.data);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, meta.per_page, handleChangePage]);

  const handleRefreshData = () => {
    setLoad(!load);
  };

  const { data, isOpen, handleCloseModal, handleOpenModal } =
    useModal<IPriceTable>(handleRefreshData);

  const actions: ActionFunction<IPriceTable> = (record) => {
    return [
      {
        key: "2",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => onDeleteItem(record),
      },
    ];
  };

  const columns: ColumnsType<IPriceTable> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value.match(/\d/g).slice(0, 4).join("")}
          </div>
        );
      },
      width: 80,
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Lucro (%)",
      dataIndex: "profit",
      key: "profit",
    },
    ActionColumn(actions),
  ];

  const onDeleteItem = async (record: IPriceTable) => {
    return modal.confirm({
      title: "Atenção!",
      content: (
        <>
          Deseja deletar esse preço?
          <br />
          <i>'{record.name}'</i>
        </>
      ),
      onOk: () =>
        api
          .delete(`/products/price-tables/${record.id}`)
          .catch(() => message.error(`Algo inesperado ocorreu!`))
          .then(handleRefreshData),
    });
  };

  return (
    <>
      <PageHeader
        title="Tabela de preços"
        backButton={false}
        actions={[
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => handleOpenModal()}
          >
            Cadastrar preço
          </Button>,
        ]}
        crumb
      />

      <PriceTableModal
        isModalOpen={isOpen}
        onClose={handleCloseModal}
        data={data}
        onRefreshData={handleRefreshData}
      />

      <Content width="calc(100% - 48px)" margin>
        <Table
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 30, 50],
            current: meta.current_page,
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
          }}
          size={"small"}
          loading={loading}
          rowKey={(record: IPriceTable) => record.id}
          columns={columns}
          dataSource={priceTable}
        />
      </Content>
    </>
  );
};

export { PriceTable };

import { FC, useEffect, useState } from "react";

import { App, Button, DatePicker, Form, Input, Modal, Spin } from "antd";

import dayjs from "dayjs";

import { api } from "../../../services/api";
import {
  IClient,
  IContract,
  ILocal,
  IPagination,
  IPmoc,
  ITechnicalManager,
  IUser,
  ModalProps,
} from "../../../types";
import { getAllContracts } from "../../contracts/list";
import { Select } from "../../../components/Select";
import { getUsers } from "../../users/list";
import { getClients, getContractLocals } from "../../../services/repositories";

const { TextArea } = Input;

export async function getTechnicalManager(page = 1, perPage = 1000) {
  return await api.get<IPagination<ITechnicalManager>>(`/technical-manager`, {
    params: {
      page,
      perPage,
    },
  });
}

const PmocModal: FC<ModalProps<IPmoc>> = ({
  onClose,
  isModalOpen,
  data: pmoc,
}) => {
  const { message } = App.useApp();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [locals, setLocals] = useState<ILocal[]>([]);
  const [date, setDate] = useState<dayjs.Dayjs>();

  const [technicalManager, setTechnicalManager] = useState<ITechnicalManager[]>(
    []
  );
  const [technical, setTechnical] = useState<IUser[]>([]);

  const EDIT_PMOC = !!pmoc;

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      await getClients(1, 1000).then(({ data }) => {
        setClients(data);
      });
      await getTechnicalManager(1, 1000).then(({ data }) => {
        setTechnicalManager(data.data);
      });
      await getUsers(1, 1000, { profile: "TECHNICIAN" }).then(({ data }) => {
        setTechnical(data.data);
      });
      if (pmoc) {
        await getAllContracts(1, 1000, {
          clientId: pmoc.clientId,
        }).then(({ data }) => {
          setContracts(data.data);
        });
        await getContractLocals({
          id: pmoc.contractId,
          page: 1,
          perPage: 1000,
        }).then(({ data }) => {
          setLocals(data);
        });
      }
    };
    if (isModalOpen) {
      getData().finally(() => {
        if (pmoc) {
          let technician = pmoc.users?.filter(
            (user) => user.profile === "TECHNICIAN"
          );
          let engineer = pmoc.users?.find(
            (user) => user.profile === "ENGINEER"
          );
          form.setFieldsValue({
            ...pmoc,
            startDate: dayjs(pmoc.startDate, "YYYY-MM-DD"),
            endDate: dayjs(pmoc.endDate, "YYYY-MM-DD"),
            engineerId: engineer?.id,
            technician: technician?.map((user) => user.id),
            locals: pmoc.locals?.map((local) => local.id),
          });
        }
        setLoading(false);
      });
    }
  }, [form, isModalOpen, pmoc]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) return;
    form.resetFields();
    setContracts([]);
    setTechnicalManager([]);
    setTechnical([]);
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
          name: values.name.toUpperCase(),
          description: values.description?.toUpperCase(),
          startDate: values.startDate?.format("YYYY-MM-DD"),
          endDate: values.endDate?.format("YYYY-MM-DD"),
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm(values)
      .then(() => {
        message.success(
          `PMOC ${!!pmoc ? "atualizado" : "criado"} com sucesso!`
        );
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: any) => {
    if (pmoc) {
      return await api.put<IPmoc>(`/pmocs/${pmoc.id}`, values);
    }
    return await api.post<IPmoc>(`/pmocs`, values);
  };

  const handleChangeClient = async (clientId: string) => {
    form.setFieldValue("contractId", null);
    setContracts([]);
    if (clientId) {
      await getAllContracts(1, 1000, {
        clientId,
      }).then(({ data }) => {
        setContracts(data.data);
      });
    }
  };

  const handleChangeContract = async (contractId: string) => {
    form.setFieldValue("localId", null);
    setLocals([]);
    if (contractId) {
      await getContractLocals({
        id: contractId,
        page: 1,
        perPage: 1000,
      }).then(({ data }) => {
        setLocals(data);
      });
    }
  };

  const disabledDate = (current: dayjs.Dayjs) => {
    if (date) {
      return current && current <= date.add(1, "day");
    }
    return true;
  };

  const handleLoadUsers = async (profile?: IUser["profile"]) => {
    if (profile === "TECHNICIAN" && technical.length > 0) {
      return;
    }
    await getUsers(1, 1000, {
      profile,
    }).then(({ data }) => {
      if (profile === "TECHNICIAN") {
        setTechnical(data.data);
      }
    });
  };

  return (
    <Modal
      centered
      title={`${pmoc ? "Editar" : "Adicionar"} PMOC`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {pmoc ? "Salvar" : "Adicionar"}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="pmoc_form_modal">
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor insira o nome do Item.",
              },
            ]}
          >
            <Input placeholder="Insira o nome" />
          </Form.Item>
          <Form.Item
            label="Cliente"
            name="clientId"
            rules={[
              {
                required: true,
                message: "Por favor selecione um cliente.",
              },
            ]}
          >
            <Select
              allowClear
              disabled={EDIT_PMOC}
              options={clients}
              onChange={handleChangeClient}
              placeholder="Selecione o cliente"
            />
          </Form.Item>
          <Form.Item
            label="Contrato"
            name="contractId"
            rules={[
              {
                required: true,
                message: "Por favor selecione um contrato.",
              },
            ]}
          >
            <Select
              disabled={EDIT_PMOC}
              onChange={handleChangeContract}
              options={contracts}
              placeholder="Selecione o contrato"
            />
          </Form.Item>
          <Form.Item
            label="Local"
            name="locals"
            rules={[
              {
                required: true,
                message: "Por favor selecione um local.",
              },
            ]}
          >
            <Select
              mode="multiple"
              disabled={EDIT_PMOC}
              options={locals}
              placeholder="Selecione o local"
            />
          </Form.Item>
          <Form.Item
            name="technicalManagerId"
            label="Responsável Técnico (Engenheiro)"
            rules={[
              {
                required: true,
                message: "Por favor selecione o responsável Técnico.",
              },
            ]}
          >
            <Select
              options={technicalManager}
              placeholder="Selecione o responsável"
            />
          </Form.Item>
          <Form.Item
            name="technician"
            label="Técnico do PMOC"
            rules={[
              {
                required: true,
                message: "Por favor selecione o Técnico.",
              },
            ]}
          >
            <Select
              mode="multiple"
              options={technical}
              handleLoadData={() => handleLoadUsers("TECHNICIAN")}
              placeholder="Selecione o técnico do PMOC"
            />
          </Form.Item>
          <Form.Item
            label="Descrição"
            name="description"
            rules={[
              {
                required: true,
                message: "Por favor insira uma descrição.",
              },
            ]}
          >
            <TextArea
              style={{ resize: "none" }}
              rows={5}
              showCount
              maxLength={255}
              placeholder="Insira a descrição"
            />
          </Form.Item>
          <Form.Item
            label="Vigência"
            required
            style={{
              margin: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                gap: 16,
              }}
            >
              <Form.Item
                name="startDate"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
                rules={[
                  {
                    required: true,
                    message: "Por favor insira a data de incio.",
                  },
                ]}
              >
                <DatePicker
                  format={"DD/MM/YYYY"}
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    setDate(value || undefined);
                  }}
                  disabled={EDIT_PMOC}
                />
              </Form.Item>
              até
              <Form.Item
                name="endDate"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
                rules={[
                  {
                    required: true,
                    message: "Por favor insira a data de fim.",
                  },
                ]}
              >
                <DatePicker
                  picker="date"
                  format={"DD/MM/YYYY"}
                  style={{ width: "100%" }}
                  disabled={EDIT_PMOC}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { PmocModal };

export const InstallmentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="10"
    fill="none"
    viewBox="0 0 11 10"
    style={{ marginRight: "10px" }}
  >
    <path
      fill="#000"
      d="M10.375 5a.406.406 0 0 1-.406.406H1.03a.406.406 0 1 1 0-.812H9.97a.406.406 0 0 1 .406.406M5.5 2.563a.812.812 0 1 0 0-1.625.812.812 0 0 0 0 1.625m0 4.874a.812.812 0 1 0 0 1.625.812.812 0 0 0 0-1.624"
    ></path>
  </svg>
);

import { RouteObject } from "react-router-dom";
import { ChartOfAccounts } from "./list";


export const ChartOfAccountsRouter: RouteObject = {
  path: "/chart-of-accounts",
  handle: { crumb: () => "Cadastros / Produtos / Plano de Contas" },
  children: [
    {
      index: true,
      element: <ChartOfAccounts />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};

import { RouteObject } from "react-router-dom";
import { PaymentMethod } from "./list";

export const PaymentMethodRouter: RouteObject = {
  path: "/payment-method",
  handle: { crumb: () => "Cadastros / Financeiro / Forma de pagamento" },
  children: [
    {
      index: true,
      element: <PaymentMethod />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};

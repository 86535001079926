import dayjs, { Dayjs } from "dayjs";
import { FC, useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message as Message,
  Modal,
  Select,
  Switch,
  Upload,
  UploadProps,
  notification,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { TOKEN_API, api } from "../../../services/api";
import { formatters, normFile, parsers } from "../../../utils";
import {
  getAllBankAccount,
  getClients,
  getSuppliers,
} from "../../../services/repositories";
import {
  ChartOfAccountSon,
  IBankAccount,
  IClient,
  IContract,
  ICostCenter,
  IPagination,
  IPayment,
  ISupplier,
  ModalProps,
} from "../../../types";
import { getAllContracts } from "../../contracts/list";

export async function getChartOfAccountSons(page = 1, perPage = 1000) {
  return await api.get<IPagination<ChartOfAccountSon>>(
    `/chart-of-accounts/sons`,
    {
      params: {
        page,
        perPage,
      },
    }
  );
}

export async function getCostCenter(page = 1, perPage = 1000) {
  return await api.get<IPagination<ICostCenter>>(
    `/financials/cost-centers/all/`,
    {
      params: {
        page,
        perPage,
      },
    }
  );
}

export async function getPaymentMethod(page = 1, perPage = 1000) {
  return await api.get<IPagination<IPayment>>(
    `/financials/payment-methods/all/`,
    {
      params: {
        page,
        perPage,
      },
    }
  );
}

const FinancialModal: FC<ModalProps> = ({
  onClose,
  isModalOpen,
  onRefreshData,
}) => {
  const [message] = Message.useMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>([]);
  const [chartOfAccountSons, setChartOfAccountSons] =
    useState<ChartOfAccountSon[]>();
  const [costCenter, setCostCenter] = useState<ICostCenter[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<IPayment[]>([]);
  const [automaticLiquidation, setAutomaticLiquidation] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const [installments, setInstallments] = useState<number[]>([]);
  const [competenceDate, setCompetenceDate] = useState<Dayjs | null>(null);

  const handleValueChange = (value: number | null) => {
    if (value === null) {
      setInstallments([]);
      return;
    }

    const newInstallments = [];
    for (let i = 1; i <= 12; i++) {
      newInstallments.push(value / i);
    }
    setInstallments(newInstallments);
  };

  const handlePaymentTypeChange = (value: string) => {
    const selectedPaymentMethod = paymentMethod.find(
      (method) => method.id === value
    );

    if (selectedPaymentMethod?.automatic_liquidation) {
      form.setFieldsValue({ automaticLiquidation: true });
      setAutomaticLiquidation(true);
      return;
    }
    form.setFieldsValue({ automaticLiquidation: false });
    setAutomaticLiquidation(false);
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const fetchContracts = async (clientId: string) => {
    try {
      const { data } = await getAllContracts(1, 1000, { clientId });
      setContracts(data?.data);
    } catch (error) {
      console.error("Erro ao buscar contratos:", error);
    }
  };

  const handleClientChange = (value: string) => {
    fetchContracts(value);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        await getAllBankAccount({
          page: 1,
          perPage: 1000,
        }).then(({ data }) => {
          setBankAccounts(data);
        });
        await getChartOfAccountSons(1, 1000).then(({ data }) => {
          setChartOfAccountSons(data?.data);
        });
        await getCostCenter(1, 1000).then(({ data }) => {
          setCostCenter(data?.data);
        });
        await getClients(1, 1000).then(({ data }) => {
          setClients(data);
        });
        await getSuppliers(1, 1000).then(({ data }) => {
          setSuppliers(data);
        });
        await getPaymentMethod(1, 1000).then(({ data }) => {
          setPaymentMethod(data.data);
        });
      } catch (error) {
        console.error(error);
      }
    };

    getData();
  }, []);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    setFiles([]);
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);

    const values = await form
      .validateFields()
      .then((values) => {
        delete values.contact;
        delete values.file;
        return values;
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    await api
      .post(`/financials/`, values)
      .then(async (data: any) => {
        notification.success({
          message: "Sucesso",
          description: "Conta cadastrada com sucesso!",
        });
        if (onRefreshData) {
          onRefreshData();
        }
        if (files) {
          const financialId = data?.data?.financial?.id;
          await handleSubmitFiles(files, financialId);
        }
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            notification.error({
              message: "Erro",
              description: err.message,
            })
          );
        } else {
          notification.error({
            message: "Erro",
            description: "Algo inesperado ocorreu!",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    action: process.env.REACT_APP_API + `/upload`,
    data: {
      folder: "financial_files",
    },
    headers: {
      authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
    },
    onChange(info) {
      if (info.file.status === "done") {
        setFiles((prevFiles) => {
          const newFiles = info.fileList
            .map((file) => file.response)
            .filter((file) => file !== undefined);

          const uniqueFiles = newFiles.filter(
            (newFile) =>
              !prevFiles.some(
                (existingFile) => existingFile.name === newFile.name
              )
          );

          return [...prevFiles, ...uniqueFiles];
        });
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        if (info.file.response?.errors) {
          info.file.response.errors.forEach((err: any) =>
            message.error(`${info.file.name} - ${err.message}`)
          );
        } else {
          message.error(`${info.file.name} Erro ao enviar o arquivo.`);
        }
      }
    },
    accept:
      "image/*,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    listType: "picture",
    maxCount: 5,
  };

  const handleSubmitFiles = async (files: any[], financialId: string) => {
    return await api.post(`/financials/files/${financialId}`, {
      files,
    });
  };

  return (
    <Modal
      title="Cadastrar conta"
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          disabled={loading}
          danger
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="financial_form_modal">
        <Form.Item name="type" label="Tipo de conta">
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
          >
            <Select.Option value="PAYMENT">Contas a pagar</Select.Option>
            <Select.Option value="RECEIPT">Contas a receber</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="document" label="Número do documento">
          <Input />
        </Form.Item>
        <Form.Item
          name="contact"
          label="Contato"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
          >
            <Select.Option value="CLIENT">Cliente</Select.Option>
            <Select.Option value="SUPPLIER">Fornecedor</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            const contactType = form.getFieldValue("contact");
            if (contactType === "CLIENT") {
              return (
                <>
                  <Form.Item
                    name="clientId"
                    label="Cliente"
                    rules={[
                      ({ getFieldValue }) => ({
                        required: getFieldValue("contact") === "CLIENT",
                        message: "Campo obrigatório!",
                      }),
                    ]}
                  >
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      placeholder="Selecione uma opção"
                      onChange={handleClientChange}
                    >
                      {clients?.map((client) => (
                        <Select.Option value={client.id} key={client.id}>
                          {client.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="contractId" label="Contrato">
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      placeholder="Selecione uma opção"
                      disabled={!form.getFieldValue("clientId")}
                    >
                      {contracts?.map((contract) => (
                        <Select.Option value={contract.id} key={contract.id}>
                          {contract.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              );
            }
            if (contactType === "SUPPLIER") {
              return (
                <>
                  <Form.Item
                    name="supplierId"
                    label="Fornecedor"
                    rules={[
                      ({ getFieldValue }) => ({
                        required: getFieldValue("contact") === "SUPPLIER",
                        message: "Campo obrigatório!",
                      }),
                    ]}
                  >
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      placeholder="Selecione uma opção"
                    >
                      {suppliers?.map((supplier) => (
                        <Select.Option value={supplier.id} key={supplier.id}>
                          {supplier.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              );
            }
          }}
        </Form.Item>
        <Form.Item
          name="competence"
          label="Data de competência"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <DatePicker
            format={"DD/MM/YYYY"}
            style={{ width: "100%" }}
            disabledDate={(currentDate) =>
              currentDate && currentDate > dayjs().endOf("day")
            }
            onChange={(date) => setCompetenceDate(date)}
          />
        </Form.Item>
        <Form.Item
          name="due"
          label="Data de vencimento"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <DatePicker
            format={"DD/MM/YYYY"}
            style={{ width: "100%" }}
            disabledDate={(currentDate) => {
              if (!competenceDate) return false;
              return currentDate < competenceDate;
            }}
          />
        </Form.Item>
        <Form.Item name="description" label="Descrição">
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="value"
          label="Valor"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <InputNumber
            formatter={formatters.currency}
            min={0.1}
            parser={parsers.currency}
            onChange={handleValueChange}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          name="paymentType"
          label="Cobrança"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
          >
            <Select.Option value="SINGLE">Única</Select.Option>
            <Select.Option value="INSTALLMENT">Parcelado</Select.Option>
            <Select.Option value="RECURRING">Recorrente</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            const paymentType = form.getFieldValue("paymentType");
            if (paymentType === "INSTALLMENT") {
              return (
                <>
                  <Form.Item
                    name="installmentQuantity"
                    label="Parcelas"
                    rules={[
                      ({ getFieldValue }) => ({
                        required:
                          getFieldValue("paymentType") === "INSTALLMENT",
                        message: "Campo obrigatório!",
                      }),
                    ]}
                  >
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      placeholder="Selecione uma opção"
                    >
                      {installments.map((installment, index) => (
                        <Select.Option key={index} value={index + 1}>
                          {`${index + 1}x de ${formatCurrency(installment)}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="frequency"
                    label="Frequência"
                    rules={[
                      ({ getFieldValue }) => ({
                        required:
                          getFieldValue("paymentType") === "INSTALLMENT",
                        message: "Campo obrigatório!",
                      }),
                    ]}
                  >
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      placeholder="Selecione uma opção"
                    >
                      <Select.Option value="FORTNIGHTLY">
                        quinzenal
                      </Select.Option>
                      <Select.Option value="MONTHLY">mensal</Select.Option>
                      <Select.Option value="BIMONTHLY">
                        bimenstral
                      </Select.Option>
                      <Select.Option value="QUARTERLY">
                        trimestral
                      </Select.Option>
                      <Select.Option value="SEMIANNUAL">
                        semestral
                      </Select.Option>
                      <Select.Option value="YEARLY">anual</Select.Option>
                    </Select>
                  </Form.Item>
                </>
              );
            }
            if (paymentType === "RECURRING") {
              return (
                <Form.Item
                  name="frequency"
                  label="Frequência"
                  rules={[
                    ({ getFieldValue }) => ({
                      required: getFieldValue("paymentType") === "RECURRING",
                      message: "Campo obrigatório!",
                    }),
                  ]}
                >
                  <Select
                    optionFilterProp="children"
                    showSearch
                    allowClear
                    placeholder="Selecione uma opção"
                  >
                    <Select.Option value="FORTNIGHTLY">quinzenal</Select.Option>
                    <Select.Option value="MONTHLY">mensal</Select.Option>
                    <Select.Option value="BIMONTHLY">bimenstral</Select.Option>
                    <Select.Option value="QUARTERLY">trimestral</Select.Option>
                    <Select.Option value="SEMIANNUAL">semestral</Select.Option>
                    <Select.Option value="YEARLY">anual</Select.Option>
                  </Select>
                </Form.Item>
              );
            }
          }}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            const type = form.getFieldValue("frequency");
            if (type === "date") {
              return (
                <Form.Item name="date" label="Data de cobrança">
                  <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
                </Form.Item>
              );
            }
            if (type === "value") {
              return (
                <Form.Item
                  name="value"
                  label="Valor de cobrança"
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <InputNumber
                    formatter={formatters.currency}
                    min={0.1}
                    parser={parsers.currency}
                    onChange={handleValueChange}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              );
            }
          }}
        </Form.Item>
        <Form.Item
          name="paymentMethodId"
          label="Forma de pagamento"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
            onChange={handlePaymentTypeChange}
          >
            {paymentMethod?.map((paymentMethod) => (
              <Select.Option value={paymentMethod.id} key={paymentMethod.id}>
                {paymentMethod.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="automaticLiquidation" label="Pagamento liquidado">
          <Switch
            checkedChildren="SIM"
            unCheckedChildren="NÃO"
            checked={automaticLiquidation}
            onChange={(checked) => setAutomaticLiquidation(checked)}
          />
        </Form.Item>
        <Form.Item
          name="bankAccountId"
          label="Conta de operação"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
          >
            {bankAccounts?.map((bankAccount) => (
              <Select.Option value={bankAccount.id} key={bankAccount.id}>
                {bankAccount.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="chartOfAccountSonsId" label="Plano de contas">
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
          >
            {chartOfAccountSons?.map((chartOfAccount) => (
              <Select.Option value={chartOfAccount.id} key={chartOfAccount.id}>
                {chartOfAccount.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="costCenterId" label="Centro de custos">
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
          >
            {costCenter?.map((costCenter) => (
              <Select.Option value={costCenter.id} key={costCenter.id}>
                {costCenter.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Anexo">
          <Form.Item
            name="file"
            valuePropName="file"
            getValueFromEvent={normFile}
            noStyle
          >
            <Upload.Dragger name="file" {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click ou arraste o arquivo para essa area de upload
              </p>
              <p className="ant-upload-hint">
                Suporte para upload único ou em massa.
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { FinancialModal };
